<template>
    <router-view />
</template>

<script>
    import { mapActions } from 'vuex'
    import { version } from '../package.json'

    export default {
        name: 'App',
        methods: {
            ...mapActions('app', [
                'initializeApp'
            ]),
            ...mapActions('api', [
                'initializeAPI'
            ]),
            ...mapActions('model', [
                'resetModel'
            ])
        },
        mounted() 
        {
            // initialize the app vuex store
            this.initializeApp(version)

            // initialize the api vuex store
            this.initializeAPI()

            
            // initialize the model vuex store (only used for Modulator currently)
            this.resetModel()
        }
    }
</script>

<style>
    html {
        overflow: hidden;
    }

    body {
        margin: 0px;
        padding: 0px;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        border: 0px;
        background-color: rgb(var(--v-theme-background));
        overflow-x: hidden;
        overflow-y: auto;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    ::-webkit-scrollbar-track {
        display: none;
    }

    ::-webkit-scrollbar-thumb {
        display: none;
    }

    ::-webkit-scrollbar-thumb:hover {
        display: none;
    }

    @media (max-width: 750px) {
        ::-webkit-scrollbar {
            display: none;
        }
    }
</style>
