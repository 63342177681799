import * as THREE from 'three'
import { BasicGeometry } from '@/classes/data/geometry/BasicGeometry'
import { OptimizedGeometry } from '@/classes/data/geometry/OptimizedGeometry'


/**
 * Geometry Factory Class
 */
export class GeometryFactory 
{
    // get all material classes
    static getClasses()
    {
        return [
            BasicGeometry,
            OptimizedGeometry
        ]
    }

    static getClass(index)
    {
        const classes = GeometryFactory.getClasses()
        if (classes && classes.length > index) return classes[index]
        return null
    }

    // get name of geometry type
    static getName(geometryClass, formatted=false)
    {
        // get type name
        const name = geometryClass.typeName()
    
        // check if name should be formatted
        if(formatted) 
            return "<b>" + name + "</b>&nbsp;Geometry"
    
        // return unformatted
        return name + " Geometry"
    }

    // get description of geometry type
    static getDescription(geometryClass)
    {
        return geometryClass.typeDescription()
    }

    // get generator component name (string) of geometry type
    static getGenerator(geometryClass)
    {
        return geometryClass.typeGenerator()
    }

    // get all geometry types
    static getTypes()
    {
        // get classes and prepare return
        const geometryClasses = this.getClasses()
        const types = []
    
        // iterate through classes
        geometryClasses.forEach((geometryClass, index) => {
    
            // collect all type-related information
            const namePlain = this.getName(geometryClass, false)
            const nameFormatted = this.getName(geometryClass, true)
            const description = this.getDescription(geometryClass)
            const generator = this.getGenerator(geometryClass)
    
            // add the type to the list
            types.push({
                index,
                namePlain,
                nameFormatted,
                description,
                generator
            })
        })
    
        // return all types
        return types
    }

    // TODO rethink the following (very similar to another function)
    static newGeometryFromDefault(mesh, geometryClass)
    {
        // get default geometry
        const defaultGeometry = mesh.getDefaultGeometry()
        const defaultThree = defaultGeometry.three()

        const indicesAttribute = defaultThree.index.clone()
        const positionAttribute = defaultThree.attributes.position.clone()
        const textureAttribute = defaultThree.attributes.uv?.clone()
        const normalAttribute = defaultThree.attributes.normal?.clone()

        const newGeometry = new geometryClass(
            mesh,
            "New Geometry",
            indicesAttribute,
            positionAttribute,
            textureAttribute,
            normalAttribute
        )

        mesh.addGeometry(newGeometry)

        return newGeometry
    }


    static newGeometryFromBuffer(mesh, geometryBuffer)
    {
        const geometryClone = new BasicGeometry(
            mesh,
            "New Clone",
            geometryBuffer.index.clone(),
            geometryBuffer.attributes.position.clone(),
            geometryBuffer.attributes.uv?.clone(),
            geometryBuffer.attributes.normal?.clone(),
        )

        mesh.addGeometry(geometryClone)

        return geometryClone
    }

    static newGeometryFromInstance(mesh, geometryInstance)
    {
        var newGeometry = geometryInstance.clone(mesh)
        return newGeometry
    }

    /*
    static newGeometryFromClass(mesh, geometryClass)
    {
        var newGeometry = geometryClass.newInstance(mesh)
        return newGeometry
    }
    */

    static newGeometryFromInstanceBySelection(mesh, geometryInstance, selection)
    {
        // get three.js buffer geometry
        const three = geometryInstance.three()


        //// INDICES

        // collect indices of triangles fully within the selection
        const selectedFaces = []

        // iterate through indices
        for (let i = 0; i < three.index.count; i += 3) 
        {
            // extract all three vertices of a face
            const a = three.index.getX(i)
            const b = three.index.getY(i)
            const c = three.index.getZ(i)
    
            // add only if all vertices are selected
            if (selection.includes(a) && 
                selection.includes(b) && 
                selection.includes(c)) 
            {
                selectedFaces.push(a, b, c)
            }
        }

        // mapping function
        const uniqueIndices = [...new Set(selection)] 
        const indexMap = new Map(uniqueIndices.map((val, idx) => [val, idx]))

        // remap the indices so they are sequential numbers starting at zero
        const newIndices = selectedFaces.map(val => indexMap.get(val))


        //// ATTRIBUTES

        // new position array
        const newPosition = new Float32Array(selection.length * 3)

        // new texture coords array
        const newTexture = three.attributes.uv ? new Float32Array(selection.length * 2) : null

        // new normal array
        const newNormal = three.attributes.normal ? new Float32Array(selection.length * 3) : null

        // iterate through selection
        for (let i = 0; i < selection.length; i++) 
        {
            // fill position array
            newPosition[(i * 3) + 0] = three.attributes.position.getX(selection[i])
            newPosition[(i * 3) + 1] = three.attributes.position.getY(selection[i])
            newPosition[(i * 3) + 2] = three.attributes.position.getZ(selection[i])

            // fill texture coords array
            if (newTexture)
            {
                newTexture[(i * 2) + 0] = three.attributes.uv.getX(selection[i])
                newTexture[(i * 2) + 1] = three.attributes.uv.getY(selection[i])
            }

            // fill normal array
            if (newNormal)
            {
                newNormal[(i * 3) + 0] = three.attributes.normal.getX(selection[i])
                newNormal[(i * 3) + 1] = three.attributes.normal.getY(selection[i])
                newNormal[(i * 3) + 2] = three.attributes.normal.getZ(selection[i])
            }
        }


        //// NEW GEOMETRY

        // create new geometry
        const newGeometry = new BasicGeometry(
            mesh,
            "New Geometry",
            new THREE.Uint32BufferAttribute(newIndices, 1),
            new THREE.BufferAttribute(newPosition, 3),
            newTexture ? new THREE.BufferAttribute(newTexture, 2) : null,
            newNormal ? new THREE.BufferAttribute(newNormal, 3) : null
        )

        // add new geometry to mesh
        mesh.addGeometry(newGeometry)

        // return new geometry
        return newGeometry
    }

    // initialize geometries from JSON
    static newGeometriesfromJSON(json, mesh)
    {
        // important: support only for wavefront obj/mat

        // ensure json exists
        if (json == null) return null


        //// DEFAULT GEOMETRIES

        // list of geometries
        const geometries = []


        //// INDICES

        // ensure indices exist
        if (!(json.indices && json.indices.length > 0)) return null

        // create buffer attribute
        const indicesAttribute = new THREE.Uint32BufferAttribute(json.indices, 1)


        //// POSITION

        // ensure position values exist
        if (!(json.position && json.position.length > 0)) return null

        // create buffer attribute
        const positionAttribute = new THREE.BufferAttribute(
            new Float32Array(json.position), 
            3
        )


        //// TEXTURE

        // create buffer attribute
        var textureAttribute = null
        if (json.texture && json.texture.length > 0)
            textureAttribute = new THREE.BufferAttribute(
                new Float32Array(json.texture), 
                2
            )


        //// NORMAL

        // create buffer attribute
        var normalAttribute = null
        if (json.normal && json.normal.length > 0)
            normalAttribute = new THREE.BufferAttribute(
                new Float32Array(json.normal), 
                3
            )


        //// COLORS

        // implemented in MaterialFactory


        //// DEFAULT GEOMETRY

        // add default geometry
        geometries.push(
            new BasicGeometry(
                mesh,
                'Default Geometry',
                indicesAttribute,
                positionAttribute,
                textureAttribute,
                normalAttribute
            )
        )

        return geometries
    }

    // export geometry to json
    static toJSON(geometry)
    {
        // get the attributes from the three.js BufferGeometry
        const attributes = geometry.nonreactive.three.attributes

        // if no geometry exist, return null
        if (attributes.position.count <= 0)
            return null

        // create json with vertex positions
        let json = {
            index: geometry.nonreactive.three.index.array,
            position: Array.from(attributes.position.array)
        }

        // add to json, only if texture coordinates exist
        if (attributes.uv && attributes.uv.count > 0)
            json.texture = Array.from(attributes.uv.array)

        // add to json, only if normal exist
        if (attributes.normal && attributes.normal.count > 0)
            json.normal = Array.from(attributes.normal.array)

        // return serialized class as json
        return json
    }

    static removeGeometryFromInstanceBySelection(geometryInstance, selection)
    {
        //TODO
    }

    static combineGeometries(mesh, firstGeometry, secondGeometry)
    {
        // get three.js buffer geometry
        const firstThree = firstGeometry.three()
        const secondThree = secondGeometry.three()

        // combine indices
        const newIndices = new Uint32Array(
            firstThree.index.array.length +
            secondThree.index.array.length
        )
        newIndices.set(firstThree.index.array)
        for (let i = 0; i < secondThree.index.array.length; i++) 
            newIndices[firstThree.index.array.length + i] = 
                secondThree.index.array[i] + firstThree.attributes.position.count
        

        // simple function to combine two arrays (for attributes)
        const combine = (firstArray, secondArray) => {
            const newArray = new Float32Array(firstArray.length + secondArray.length)
            newArray.set(firstArray)
            newArray.set(secondArray, firstArray.length)
            return newArray
        }

        // combine position attributes
        const newPosition = combine(
            firstThree.attributes.position.array,
            secondThree.attributes.position.array
        )

        // combine texture coord attributes
        const texture = firstThree.attributes.uv && secondThree.attributes.uv
        const newTexture = texture ? combine(
            firstThree.attributes.uv.array,
            secondThree.attributes.uv.array
        ) : null

        // combine normal attributes
        const normal = firstThree.attributes.normal && secondThree.attributes.normal
        const newNormal = normal ? combine(
            firstThree.attributes.normal.array,
            secondThree.attributes.normal.array
        ) : null

        // create new geometry
        const newGeometry = new BasicGeometry(
            mesh,
            "New Geometry",
            new THREE.Uint32BufferAttribute(newIndices, 1),
            new THREE.BufferAttribute(newPosition, 3),
            newTexture ? new THREE.BufferAttribute(newTexture, 2) : null,
            newNormal ? new THREE.BufferAttribute(newNormal, 3) : null
        )

        // add new geometry to mesh
        mesh.addGeometry(newGeometry)

        // return new geometry
        return newGeometry
    }
}