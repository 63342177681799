import { reactive } from 'vue'
import * as THREE from 'three'

/*
 * Basic Geometry Class
 */
export class BasicGeometry 
{
    //// PROPERTIES

    // STATIC
    static lastId = 0   // remember last id 

    // CONSTANT
    // [id] unique identifier of geometry; incremented for each instantiated geometry object
    // [mesh] reference to parent mesh (the geometry is part of) 
    // [locked] if true, geometry should not be changed (e.g., used for default geometries)

    // REACTIVE
    // [name] name of the geometry (do not confuse with the name of the geometry type)
    // [version] internal counter to track current iteration of geometry (for external listeners)

    // NONREACTIVE
    // [state] stores all variables defining the state of this geometry (basically, its configuration)
    // [three] three.js BufferGeometry containing position, texture uvs, and normals
    // [extent] distance from origin to farest vertices of this geometry


    //// METHODS

    // geometry type name
    static typeName()
    {
        return 'Basic'
    }
    
    // geometry type description
    static typeDescription()
    {
        return 'Original geometry of the mesh'
    }
    
    // geometry type generator
    static typeGenerator()
    {
        return null
    }

    static newInstance(mesh)
    {
        //TODO
    }

    // class constructor
    constructor(
        mesh, 
        name, 
        indiciesAttribute,
        positionAttribute,
        textureAttribute,
        normalAttribute,
        locked=false,
        update=true)
    {
        // constant properties
        this.id = ++BasicGeometry.lastId
        this.mesh = mesh
        this.locked = locked

        // reactive properties
        this.reactive = reactive({
            name,
            version: 0
        })

        // nonreactive properties
        this.nonreactive = {
            state: {},
            three: null,
            box: null,
        }

        // initialize three.js geometry
        this.nonreactive.three = new THREE.BufferGeometry()

        // attach indicies attribute to geometry
        this.nonreactive.three.setIndex(indiciesAttribute)

        // attach position attribute to geometry
        this.nonreactive.three.setAttribute(
            'position',
            positionAttribute
        )
        
        // attach texture attribute to geometry
        if (textureAttribute)
            this.nonreactive.three.setAttribute(
                'uv',
                textureAttribute
            )
        
        // attach normal attribute to geometry
        if (normalAttribute)
        {
            this.nonreactive.three.setAttribute(
                'normal',
                normalAttribute
            )
        }
        else
        {
            this.nonreactive.three.computeVertexNormals()
        }

        // apply state
        if (update) this.update(false)
    }

    // update geometry
    // propagate: if true, the update propagates upward the hierarchy (i.e. update is triggered for mesh of geometry as well)
    update(propagate=true)
    {
        // get position attribute
        const positionAttribute = this.nonreactive.three.attributes.position

        // ensure boundary box exists
        if (this.nonreactive.box == null) 
            this.nonreactive.box = new THREE.Box3()
    
        // apply current state of position attribute to boundary box
        this.nonreactive.box.setFromBufferAttribute(positionAttribute)

        // calculate tree for improved raytracing (bounding volume hierarchy)
        this.nonreactive.three.computeBoundsTree()

        // update took place
        this.reactive.version += 1

        // propagate update to parent mesh
        if(propagate) this.mesh.update()
    }

    clone(mesh)
    {
        const geometryClone = new BasicGeometry(
            mesh,
            "New Clone",
            this.nonreactive.three.index.clone(),
            this.nonreactive.three.attributes.position.clone(),
            this.nonreactive.three.attributes.uv.clone(),
            this.nonreactive.three.attributes.normal.clone(),
        )

        mesh.addGeometry(geometryClone)

        return geometryClone
    }

    // has geometry texture coordinates (UVs)?
    hasUVs()
    {
        const textureAttribute = this.nonreactive.three.attributes.uv
        if (textureAttribute == null) return false
        if (textureAttribute.count <= 0) return false
        return true
    }

    // has geometry normals?
    hasNormals()
    {
        const normalAttribute = this.nonreactive.three.attributes.normal
        if (normalAttribute == null) return false
        if (normalAttribute.count <= 0) return false
        return true
    }

    // geometry locked
    isLocked()
    {
        return this.locked
    }

    // geometry three
    // returns the current three.js geometry
    three()
    {
        // return three.js geometry
        return this.nonreactive.three
    }

    // geometry box
    // return the boundary box of the mesh
    box()
    {
        return this.nonreactive.box
    }

    // geometry center
    // return the center of the boundary box
    center()
    {
        const modelCenter = new THREE.Vector3()
        return this.nonreactive.box.getCenter(modelCenter)
    }

    // geometry summary
    // returns a summary of this geometry
    summary(print=true)
    {
        // begin message
        let msg = "\n[Geometry Summary]\n"

        // constant properties
        msg += "id: " + this.id + "\n"
        msg += "mesh: " + this.mesh.id + "\n"

        // reactive properties
        msg += "name: " + this.reactive.name + "\n"
        msg += "version: " + this.reactive.version + "\n"

        // log to console
        if(print) console.log(msg)

        return msg
    }
}