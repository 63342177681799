//// STRING

// shorten string to the given length
export function shortenString(str, len) 
{
    // if string is too long, shorten it
    if (str.length > len)
        return str.substring(0, len - 3) + "..."

    // string does not require shortening
    return str
}

// format a string (i.e. capitalize individual words and remove special character)
export function formatString(str) 
{
    // add whitespaces in front of capital letters
    var stringWithSpaces = str.replace(/(?!^)([A-Z])/g, ' $1')

    // remove all special characters
    let stringWithoutSpecialChars = stringWithSpaces.replace(/[^a-zA-Z0-9\s]/g, ' ')

    // convert to array of words
    let words = stringWithoutSpecialChars.split(/\s+/)

    // capitalize first letter of every word
    let capitalizedWords = words.map(word => {
        if (word.length === 0) {
            return word
        }
        return word[0].toUpperCase() + word.substr(1).toLowerCase()
    })

    // combine array elements to string
    let finalString = capitalizedWords.join(' ')

    return finalString
}