import { Model } from '@/classes/data/Model.js'
import { MeshFactory } from '@/classes/factory/MeshFactory.js'

/*
 * Model Factory Class
 */
export class ModelFactory {

    static newModel() {
        const model = new Model("New Model")
        return model
    }

    // initialize model from JSON
    static async newModelfromJSON(json, callbackFinished = null, callbackProgress = null) {
        // ensure json exists
        if (json == null) return

        // get model name
        const name = json.name ? json.name : "Unnamed"

        // create model
        const model = new Model(name)

        // flag model as not ready
        model.setReady(false)

        // empty list of meshes
        const meshes = model.getMeshes()

        // function to load a mesh asynchronously
        const loadMesh = async (jsonMesh) => {

            // skip the mesh if it has no geometry
            // TODO: leads to changed export object
            if (jsonMesh.geometry == null) return

            // using a promise here to handle the setTimeout
            await new Promise((resolve) => {

                // timeout set to 50 to allow UI thread to update
                setTimeout(() => {
                    try {
                        // create mesh from json
                        const mesh = MeshFactory.newMeshfromJSON(jsonMesh, model)

                        // add mesh to array
                        meshes.push(mesh)

                        // resolve after the mesh is added
                        resolve()
                    }
                    catch (error) {
                        // debug error message
                        console.error("error importing mesh: ", error)

                        // resolve even in case of error to continue the loop
                        resolve()
                    }
                }, 50)
            })
        }

        // iterate through each mesh and load sequentially
        for (let i = 0; i < json.meshes.length; i++) {
            // get current mesh
            const jsonMesh = json.meshes[i]

            // load current mesh
            await loadMesh(jsonMesh)

            // trigger callback
            if (callbackProgress && typeof callbackProgress !== undefined)
                callbackProgress(i / json.meshes.length)
        }

        // auto adjust the model pose
        model.autoAdjust()

        // sort the meshes
        meshes.sort((a, b) => {
            if (a.reactive.name < b.reactive.name)
                return -1
            if (a.reactive.name > b.reactive.name)
                return 1
            return 0
        })

        // flag model as ready after all meshes are loaded
        model.setReady(true)

        // trigger callback
        if (callbackFinished && typeof callbackFinished !== undefined)
            setTimeout(callbackFinished(model))
    }

    // export model to json
    static async toJSON(model, callbackFinished = null, callbackProgress = null) {
        // create empty json
        let json = {}

        // set name
        json.name = model.reactive.name

        // create empty meshes
        json.meshes = []

        // function to load a mesh asynchronously
        const loadMesh = async (mesh) => {

            // using a promise here to handle the setTimeout
            await new Promise((resolve) => {
                // timeout set to 0 to allow UI thread to update
                setTimeout(() => {
                    try {

                        // get current mesh
                        const m = MeshFactory.toJSON(mesh)

                        // add current mesh
                        json.meshes.push(m)

                        // resolve after the mesh is added
                        resolve()
                    }
                    catch (error) {
                        // debug error message
                        console.error("error exporting mesh: ", error)

                        // resolve even in case of error to continue the loop
                        resolve()
                    }
                }, 50) // delay for unfreezing UI, not ideal
            })
        }

        // iterate through each mesh
        for (let i = 0; i < model.nonreactive.meshes.length; i++) {
            // get current mesh
            const mesh = model.nonreactive.meshes[i]

            // load current mesh
            await loadMesh(mesh)

            // trigger callback
            if (callbackProgress && typeof callbackProgress === 'function')
                callbackProgress(i / model.nonreactive.meshes.length)
        }

        // trigger callback
        if (callbackFinished && typeof callbackFinished === 'function')
            setTimeout(callbackFinished(json))
    }
}