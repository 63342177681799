/**
 * Container Class
 */
export class Container
{
    //// PROPERTIES

    // STATIC
    static lastId = 0   // remember last id 

    // DYNAMIC
    // [label]
    // [dimensions]


    //// METHODS

    // class constructor
    constructor(label, width, height, depth) 
    {
        // static properties
        this.id = ++Container.lastId

        // dynamic properties
        this.label = label
        this.dimensions = {
            width,
            height,
            depth
        }
    }

    stringDimensions()
    {
        const { width, height, depth } = this.dimensions
        return `${width} mm x ${height} mm x ${depth} mm`
    }
}

export class PerfectContainer extends Container
{
    constructor()
    {
        super('Perfect Box', 0, 0, 0)
    }

    updateDimensions(width, height, depth)
    {
        this.dimensions = { width, height, depth }
    }
}