// vuex.js state of viewer (viewing model)


// info
// camera (analogy: comparable to a satellite flying aroung earth)
// theta: tilt (analogy: satellite movement from north to south pole)
// phi: yaw (analogy: satellite would describe movement around the equator)
// no roll implemented


//// CONSTANTS

// mode
const MODE_RENDER = 'render'
const MODE_TEXTURE = 'texture'
const MODE_NORMALS = 'normals'
const MODE_DEPTH = 'depth'
const MODE_DEFAULT = MODE_RENDER

// target
const TARGET_MESH = 'mesh'
const TARGET_SET = 'set'
const TARGET_FACE = 'face'
const TARGET_EDGE = 'edge'
const TARGET_VERTEX = 'vertex'
const TARGET_DEFAULT = TARGET_MESH

// cursor
const CURSOR_POINTER = 'pointer'
const CURSOR_SELECT = 'select'
const CURSOR_DEFAULT = CURSOR_POINTER

// preset
const PRESET_DEFAULT = 0
const PRESET_STUDIO = -1

// camera
const CAMERA_THETA_DEFAULT = 0
const CAMERA_PHI_DEFAULT = Math.PI / 2.5
const CAMERA_RADIUS_DEFAULT = 5

// limit
const LIMIT_DEFAULT = true
const LIMIT_PHI_MIN = 20 * (Math.PI / 180) // convert degree to rad
const LIMIT_PHI_MAX = 160 * (Math.PI / 180) // convert degree to rad
const LIMIT_RADIUS_MIN = 1
const LIMIT_RADIUS_MAX = 20

// animation
const ANIMATION_FLY_DEFAULT = true
const ANIMATION_FLY_VALUE = 0.05
const ANIMATION_TURNTABLE_DEFAULT = false
const ANIMATION_TURNTABLE_VALUE = 0.3


//// CAMERA

function newCameraState() {
    return {
        center: {
            x: 0,
            y: 0,
            z: 0
        },
        theta: CAMERA_THETA_DEFAULT,
        phi: CAMERA_PHI_DEFAULT,
        radius: CAMERA_RADIUS_DEFAULT
    }
}


//// STATE

function newState() {
    return {

        // active
        activeMode: MODE_DEFAULT,
        activeTarget: TARGET_DEFAULT,
        activeCursor: CURSOR_DEFAULT,

        // enabled
        enabledMode: {
            [MODE_RENDER]: true,
            [MODE_TEXTURE]: true,
            [MODE_NORMALS]: true,
            [MODE_DEPTH]: true
        },
        enabledTargets: {
            [TARGET_MESH]: true,
            [TARGET_SET]: true,
            [TARGET_FACE]: true,
            [TARGET_EDGE]: false,
            [TARGET_VERTEX]: false
        },
        enabledCursor: {
            [CURSOR_POINTER]: true,
            [CURSOR_SELECT]: false
        },
        
        // studio
        studio: {
            enabled: false,
            resolution: null,
            mode: null
        },

        // presets
        presets: {
            [PRESET_STUDIO]: null,
            [PRESET_DEFAULT]: newCameraState()
        },
        activePresetKey: PRESET_DEFAULT,

        // selection
        selection: {},

        // focus
        focus: 0,

        // offset
        offset: {
            x: 0,
            y: 0
        },

        // limit
        limit: LIMIT_DEFAULT,
        limitPhi: {
            min: LIMIT_PHI_MIN,
            max: LIMIT_PHI_MAX
        },
        limitRadius: {
            min: LIMIT_RADIUS_MIN,
            max: LIMIT_RADIUS_MAX
        },

        // animation
        animationFly: ANIMATION_FLY_DEFAULT,
        animationTurntable: ANIMATION_TURNTABLE_DEFAULT
    }
}


//// VUEX STORE

export const viewer = {
    namespaced: true,
    state: newState(),
    mutations: {
        RESET_VIEWER(state)
        {
            state = newState()
        },
        RESET_SELECTION(state)
        {
            state.selection = {}
        },
        SET_ACTIVE_MODE(state, activeMode) 
        {
            state.activeMode = activeMode
        },
        SET_ACTIVE_TARGET(state, activeTarget)
        {
            state.activeTarget = activeTarget
        },
        SET_ACTIVE_CURSOR(state, activeCursor)
        {
            state.activeCursor = activeCursor
            state.activeTarget = TARGET_DEFAULT
            state.selection = {}
        },
        SET_ENABLED_MODE(state, { mode, enabled })
        {
            state.enabledMode[mode] = enabled
        },
        SET_ENABLED_TARGET(state, { target, enabled })
        {
            state.enabledTarget[target] = enabled
        },
        SET_ENABLED_CURSOR(state, { cursor, enabled })
        {
            state.enabledCursor[cursor] = enabled
        },
        SET_ENABLED_MODE(state, { mode, enabled })
        {
            state.enabledMode[mode] = enabled
        },
        ACTIVATE_STUDIO(state, { resolution, mode })
        {
            state.studio.enabled = true
            state.studio.resolution = resolution
            state.studio.mode = mode
            state.presets[PRESET_STUDIO] = newCameraState()
        },
        DEACTIVATE_STUDIO(state)
        {
            state.studio.enabled = false
            state.studio.resolution = null
            state.studio.mode = null
            state.presets[PRESET_STUDIO] = null
        },
        SET_ACTIVE_PRESET_KEY(state, presetKey)
        {
            state.activePresetKey = presetKey
        },
        SET_PRESET_CENTER(state, { presetKey, centerX, centerY, centerZ }) 
        {
            // ensure preset exists
            if (!state.presets[presetKey])
                state.presets[presetKey] = newCameraState()

            // set center position
            state.presets[presetKey].center = {
                x: centerX,
                y: centerY,
                z: centerZ
            }
        },
        SET_PRESET_THETA(state, { presetKey, theta }) 
        {
            // ensure preset exists
            if (!state.presets[presetKey])
                state.presets[presetKey] = newCameraState()

            // set theta angle
            state.presets[presetKey].theta = theta
        },
        SET_PRESET_PHI(state, { presetKey, phi }) 
        {
            // ensure preset exists
            if (!state.presets[presetKey])
                state.presets[presetKey] = newCameraState()

            // set phi angle
            if (state.limit)
            {
                const limitMax = state.limitPhi.max
                const limitMin = state.limitPhi.min
                state.presets[presetKey].phi = Math.min(limitMax, Math.max(limitMin, phi))
            }
            else
                state.presets[presetKey].phi = phi
        },
        SET_PRESET_RADIUS(state, { presetKey, radius})
        {
            // ensure preset exists
            if (!state.presets[presetKey])
                state.presets[presetKey] = newCameraState()

            // set radius
            if (state.limit) 
            {
                const limitMax = state.limitRadius.max
                const limitMin = state.limitRadius.min
                state.presets[presetKey].radius = Math.min(limitMax, Math.max(limitMin, radius))
            }
            else
                state.presets[presetKey].radius = radius
        },
        SET_SELECTION(state, { mesh, select, selection }) 
        {
            // if mesh (key) does NOT exist yet
            if (!state.selection.hasOwnProperty(mesh)) 
            {
                // only if something is selected, we need to store it
                if (select) state.selection[mesh] = [...selection]
                else return
            } 
            // if mesh (key) exists
            else 
            {
                // add selection
                if (select) 
                {
                    const currentSelection = new Set(state.selection[mesh])
                    selection.forEach(id => currentSelection.add(id))
                    state.selection[mesh] = Array.from(currentSelection)
                } 
                // remove selection
                else state.selection[mesh] = state.selection[mesh].filter(id => !selection.includes(id))
            }
        },
        SET_FOCUS(state, focus)
        {
            state.focus = focus
        },
        SET_OFFSET(state, { x, y }) 
        {
            state.offset.x = x
            state.offset.y = y
        },
        SET_LIMIT(state, limit)
        {
            state.limit = limit
        },
        SET_PHI_LIMIT(state, {limitMin, limitMax})
        {
            state.limitPhi.min = limitMin
            state.limitPhi.max = limitMax
        },
        SET_RADIUS_LIMIT(state, {limitMin, limitMax})
        {
            state.limitRadius.min = limitMin
            state.limitRadius.max = limitMax
        },
        SET_ANIMATION_FLY(state, animationFly)
        {
            state.animationFly = animationFly
        },
        SET_ANIMATION_TURNTABLE(state, animationTurntable)
        {
            state.animationTurntable = animationTurntable
        }
    },
    getters: {
        keyModeDefault: () => MODE_DEFAULT,
        keyModeRender: () => MODE_RENDER,
        keyModeTexture: () => MODE_TEXTURE,
        keyModeNormals: () => MODE_NORMALS,
        keyModeDepth: () => MODE_DEPTH,
        keyTargetDefault: () => TARGET_DEFAULT,
        keyTargetMesh: () => TARGET_MESH,
        keyTargetSet: () => TARGET_SET,
        keyTargetFace: () => TARGET_FACE,
        keyTargetEdge: () => TARGET_EDGE,
        keyTargetVertex: () => TARGET_VERTEX,
        keyCursorDefault: () => CURSOR_DEFAULT,
        keyCursorPointer: () => CURSOR_POINTER,
        keyCursorSelect: () => CURSOR_SELECT,
        keyPresetDefault: () => PRESET_DEFAULT,
        keyPresetStudio: () => PRESET_STUDIO,
        keyAnimationFlyDefault: () => ANIMATION_FLY_DEFAULT,
        keyAnimationFlyValue: () => ANIMATION_FLY_VALUE,
        keyAnimationTurntableDefault: () => ANIMATION_TURNTABLE_DEFAULT,
        keyAnimationTurntableValue: () => ANIMATION_TURNTABLE_VALUE,
        getActiveMode: state => {
            if (state.studio.enabled && state.studio.mode) return state.studio.mode
            return state.activeMode
        },
        getActiveTarget: state => state.activeTarget,
        getActiveCursor: state => state.activeCursor,
        getResolution: state => {
            if (state.studio.enabled) return state.studio.resolution
            return null
        },
        getPreset: (state) => (presetKey) => {
            // get preset key (studio overwrites key)
            let key = state.studio.enabled ? PRESET_STUDIO : presetKey
            // make sure preset exists
            if (!state.presets[key]) state.presets[key] = newCameraState()
            // return preset
            return state.presets[key]
        },
        getActivePreset: (state, getters) => {
            return getters.getPreset(state.activePresetKey)
        },
        getActivePresetKey: (state) => {
            return state.studio.enabled ? PRESET_STUDIO : state.activePresetKey
        },
        getActivePresetCenter: (state, getters) => {
            const preset = getters.getActivePreset
            return { ...preset.center }
        },
        getActivePresetAngles: (state, getters) => {
            const preset = getters.getActivePreset
            return [ preset.theta, preset.phi ]
        },
        getActivePresetTheta: (state, getters) => {
            const preset = getters.getActivePreset
            return preset.theta
        },
        getActivePresetPhi: (state, getters) => {
            const preset = getters.getActivePreset
            return preset.phi
        },
        getActivePresetRadius: (state, getters) => {
            const preset = getters.getActivePreset
            return preset.radius
        },
        getSelection: state => state.selection,
        getFocus: state => state.focus,
        getOffset: state => ({ ...state.offset }),
        getLimitPhi: (state) => ({ ...state.limitPhi }),
        getLimitRadius: (state) => ({ ...state.limitRadius }),
        isModeDefault: (state, getters) => getters.getActiveMode === MODE_DEFAULT,
        isModeRender: (state, getters) => getters.getActiveMode === MODE_RENDER,
        isModeTexture: (state, getters) => getters.getActiveMode === MODE_TEXTURE,
        isModeNormals: (state, getters) => getters.getActiveMode === MODE_NORMALS,
        isModeDepth: (state, getters) => getters.getActiveMode === MODE_DEPTH,
        isTargetDefault: state => state.activeTarget === TARGET_DEFAULT,
        isTargetMesh: state => state.activeTarget === TARGET_MESH,
        isTargetSet: state => state.activeTarget === TARGET_SET,
        isTargetFace: state => state.activeTarget === TARGET_FACE,
        isTargetEdge: state => state.activeTarget === TARGET_EDGE,
        isTargetVertex: state => state.activeTarget === TARGET_VERTEX,
        isCursorDefault: state => state.activeCursor === CURSOR_DEFAULT,
        isCursorPointer: state => state.activeCursor === CURSOR_POINTER,
        isCursorSelect: state => state.activeCursor === CURSOR_SELECT,
        isModeEnabled: (state) => (mode) => {
            return state.enabledMode[mode]
        },
        isTargetEnabled: (state) => (target) => {
            return state.enabledTargets[target]
        },
        isCursorEnabled: (state) => (cursor) => {
            return state.enabledCursor[cursor]
        },
        isStudio: (state) => state.studio.enabled,
        isPreset: (state) => (presetId) => {
            if (state.presets[presetId]) return true
            else return false
        },
        isSelection: state => {
            if (state.selection && Object.keys(state.selection).length) {
                for (let mesh of Object.keys(state.selection)) {
                    if (state.selection[mesh].length) return true
                }
            }
            return false
        },
        isShowHover: state => {
            if (state.studio.enabled) return false
            return true
        },
        isShowSelection: state => {
            if (state.studio.enabled) return false
            if (state.activeCursor === CURSOR_SELECT) return true
            return false
        },
        isShowSets: state => {
            if (state.studio.enabled) return false
            if (state.activeTarget === TARGET_SET) return true
            return false
        },
        isLimit: (state) => {
            if (state.studio.enabled) return false
            return state.limit
        },
        isAnimationFly: (state) => state.animationFly,
        isAnimationTurntable: (state) => state.animationTurntable
    },
    actions: {
        resetViewer({ commit })
        {
            commit('RESET_VIEWER')
        },
        resetSelection({ commit }) 
        {
            commit('RESET_SELECTION')
        },
        setActiveMode({ commit }, mode) 
        {
            commit('SET_ACTIVE_MODE', mode)
        },
        setActiveModeToDefault({ commit }) 
        {
            commit('SET_ACTIVE_MODE', MODE_DEFAULT)
        },
        setActiveModeToRender({ commit }) 
        {
            commit('SET_ACTIVE_MODE', MODE_RENDER)
        },
        setActiveModeToTexture({ commit }) 
        {
            commit('SET_ACTIVE_MODE', MODE_TEXTURE)
        },
        setActiveModeToNormals({ commit }) 
        {
            commit('SET_ACTIVE_MODE', MODE_NORMALS)
        },
        setActiveModeToDepth({ commit }) 
        {
            commit('SET_ACTIVE_MODE', MODE_DEPTH)
        },
        setActiveTarget({ commit }, target) 
        {
            commit('SET_ACTIVE_TARGET', target)
        },
        setActiveTargetToDefault({ commit }) 
        {
            commit('SET_ACTIVE_TARGET', TARGET_DEFAULT)
        },
        setActiveTargetToMesh({ commit }) 
        {
            commit('SET_ACTIVE_TARGET', TARGET_MESH)
        },
        setActiveTargetToSet({ commit }) 
        {
            commit('SET_ACTIVE_TARGET', TARGET_SET)
        },
        setActiveTargetToFace({ commit }) 
        {
            commit('SET_ACTIVE_TARGET', TARGET_FACE)
        },
        setActiveTargetToEdge({ commit }) 
        {
            commit('SET_ACTIVE_TARGET', TARGET_EDGE)
        },
        setActiveViewerTargetToVertex({ commit }) 
        {
            commit('SET_ACTIVE_TARGET', TARGET_VERTEX)
        },
        setActiveCursor({ commit }, cursor) 
        {
            commit('SET_ACTIVE_CURSOR', cursor)
        },
        setActiveCursorToDefault({ commit }) 
        {
            commit('SET_ACTIVE_CURSOR', CURSOR_DEFAULT)
        },
        setActiveCursorToPointer({ commit }) 
        {
            commit('SET_ACTIVE_CURSOR', CURSOR_POINTER)
        },
        setActiveCursorToSelect({ commit }) 
        {
            commit('SET_ACTIVE_CURSOR', CURSOR_SELECT)
        },
        setEnabledMode({ commit }, { mode, enabled })
        {
            commit('SET_ENABLED_MODE', { mode, enabled })
        },
        setEnabledTarget({ commit }, { target, enabled })
        {
            commit('SET_ENABLED_TARGET', { target, enabled })
        },
        setEnabledCursor({ commit }, { cursor, enabled })
        {
            commit('SET_ENABLED_CURSOR', { cursor, enabled })
        },
        activateStudio({ commit }, { resolution, mode }) 
        {
            commit('ACTIVATE_STUDIO', { resolution, mode })
        },
        deactivateStudio({ commit }) 
        {
            commit('DEACTIVATE_STUDIO')
        },
        setActivePreset({ commit, getters }, preset)
        {
            const presetKey = getters.getActivePresetKey
            commit('SET_PRESET_CENTER', { 
                presetKey, 
                centerX: preset.center.x, 
                centerY: preset.center.y, 
                centerZ: preset.center.z 
            })
            commit('SET_PRESET_THETA', { presetKey, theta: preset.theta })
            commit('SET_PRESET_PHI', { presetKey, phi: preset.phi })
            commit('SET_PRESET_RADIUS', { presetKey, radius: preset.radius })
        },
        setActivePresetKey({ commit }, presetKey)
        {
            commit('SET_ACTIVE_PRESET_KEY', presetKey)
        },
        setActivePresetCenter({ commit, getters }, { centerX, centerY, centerZ })
        {
            const presetKey = getters.getActivePresetKey
            commit('SET_PRESET_CENTER', { presetKey, centerX, centerY, centerZ })
        },
        setActivePresetTheta({ commit, getters }, theta)
        {
            const presetKey = getters.getActivePresetKey
            commit('SET_PRESET_THETA', { presetKey, theta })
        },
        setActivePresetPhi({ commit, getters }, phi)
        {
            const presetKey = getters.getActivePresetKey
            commit('SET_PRESET_PHI', { presetKey, phi })
        },
        setActivePresetRadius({ commit, getters }, radius)
        {
            const presetKey = getters.getActivePresetKey
            commit('SET_PRESET_RADIUS', { presetKey, radius })
        },
        setSelectionAll({ commit }, { mesh, select, count })
        {
            const selection = Array.from({ length: count }, (_, i) => i)
            commit('SET_SELECTION', { mesh, select, selection })
        },
        setSelection({ commit }, { mesh, select, selection })
        {
            commit('SET_SELECTION', { mesh, select, selection })
        },
        setFocus({ commit }, focus)
        {
            commit('SET_FOCUS', focus)
        },
        setOffset({ commit }, { x, y }) {
            commit('SET_OFFSET', { x, y })
        },
        limitCamera({ commit }) 
        {
            commit('SET_CAMERA_LIMIT', true)
        },
        unlimitCamera({ commit }) 
        {
            commit('SET_CAMERA_LIMIT', false)
        },
        setLimitPhi({ commit }, { limitMin, limitMax }) 
        {
            commit('SET_LIMIT_PHI', { limitMin, limitMax })
        },
        setLimitRadius({ commit }, { limitMin, limitMax }) 
        {
            commit('SET_LIMIT_RADIUS', { limitMin, limitMax })
        },
        setAnimationFly({ commit }, animationFly) 
        {
            commit('SET_ANIMATION_FLY', animationFly)
        },
        toggleAnimationFly({ commit, getters })
        {
            const isAnimation = getters.isAnimationFly
            commit('SET_ANIMATION_FLY', !isAnimation)
        },
        setAnimationTurntable({ commit }, animationTurntable) 
        {
            commit('SET_ANIMATION_TURNTABLE', animationTurntable)
        },
        toggleAnimationTurntable({ commit, getters })
        {
            const isAnimation = getters.isAnimationTurntable
            commit('SET_ANIMATION_TURNTABLE', !isAnimation)
        }
    }
}