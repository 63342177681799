import { createStore } from 'vuex'
import { app } from '@/stores/vuex-app'
import { api } from '@/stores/vuex-api'
import { model } from '@/stores/vuex-model'
import { viewer } from '@/stores/vuex-viewer'
import { sketchurizer } from '@/stores/vuex-sketchurizer'
import { modulator } from '@/stores/vuex-modulator'
import { texturizer } from '@/stores/vuex-texturizer'
import { boxurizer } from '@/stores/vuex-boxurizer'

const store = createStore({
    modules: {
        // base modules
        app,
        api,
        model,
        viewer,

        // app modules
        sketchurizer,
        modulator,
        texturizer,
        boxurizer
    }
})

//TODO init vuex stores here

export default store