import * as THREE from 'three'
import store from '@/plugins/vuex'

export class ShaderFactory
{
    static modeAdjustedMaterial(defaultMaterial)
    {
        // variable for shader material
        let shaderMaterial = null

        // assign material based on mode
        if (store.getters['viewer/isModeRender'])
            shaderMaterial = defaultMaterial
        else if (store.getters['viewer/isModeTexture'])
            shaderMaterial = ShaderFactory.newTextureShader()
        else if (store.getters['viewer/isModeNormals'])
            shaderMaterial = ShaderFactory.newNormalsShader()
        else if (store.getters['viewer/isModeDepth'])
            shaderMaterial = ShaderFactory.newDepthShader()
        else 
            console.error('no shader available for selected mode')

        // return shader material
        return shaderMaterial
    }

    //// SHADER

    // standard shader
    static newStandardShader(customOptions = {}) {

        // get uniforms from options
        const options = ShaderFactory.#getDefaultOptions(customOptions)
        const customUniforms = ShaderFactory.#getCustomUniforms(options)
        
        const material = new THREE.MeshStandardMaterial({
            transparent: true,
            opacity: options.opacity
        })

        material.customUniforms = customUniforms

        // define custom uniforms with global scope
        material.onBeforeCompile = function(shader) {

            // add custom uniforms to the shader
            Object.assign(shader.uniforms, customUniforms)
    
            // adjust vertex shader
            shader.vertexShader = shader.vertexShader.replace(
                '#include <common>', 
                ShaderFactory.#getVertexDeclaration() + '#include <common>'
            )
            shader.vertexShader = shader.vertexShader.replace(
                '#include <begin_vertex>', 
                ShaderFactory.#getVertexFunction() + '#include <begin_vertex>'
            )
    
            // adjust fragment shader
            shader.fragmentShader = shader.fragmentShader.replace(
                'uniform float opacity;',
                ''
            )

            shader.fragmentShader = ShaderFactory.#getFragmentDeclaration() + shader.fragmentShader

            shader.fragmentShader = shader.fragmentShader.replace(
                'vec4 diffuseColor = vec4( diffuse, opacity );', 
                'vec3 newColor = diffuse.xyz;' +
                ShaderFactory.#getFragmentFunction() +
                'vec4 diffuseColor = vec4(newColor, opacity);'
            )
        }

        return material
    }

    // vertex color shader
    static newVertexColorShader(customOptions = {}) {

        // get uniforms from options
        const options = ShaderFactory.#getDefaultOptions(customOptions)
        const customUniforms = ShaderFactory.#getCustomUniforms(options)
                
        const material = new THREE.MeshBasicMaterial({
            transparent: true,
            opacity: options.opacity,
            vertexColors: THREE.VertexColors
        })
        
        material.customUniforms = customUniforms
        
        // define custom uniforms with global scope
        material.onBeforeCompile = function(shader) {
        
            // add custom uniforms to the shader
            Object.assign(shader.uniforms, customUniforms)
            
            // adjust vertex shader
            shader.vertexShader = shader.vertexShader.replace(
                '#include <common>', 
                ShaderFactory.#getVertexDeclaration() + '#include <common>'
            )
            shader.vertexShader = shader.vertexShader.replace(
                '#include <begin_vertex>', 
                ShaderFactory.#getVertexFunction() + '#include <begin_vertex>'
            )
            
            // adjust fragment shader
            shader.fragmentShader = shader.fragmentShader.replace(
                'uniform float opacity;',
                ''
            )
        
            shader.fragmentShader = ShaderFactory.#getFragmentDeclaration() + shader.fragmentShader
        
            shader.fragmentShader = shader.fragmentShader.replace(
                'vec4 diffuseColor = vec4( diffuse, opacity );', 
                'vec3 newColor = diffuse.xyz;' +
                ShaderFactory.#getFragmentFunction() +
                'vec4 diffuseColor = vec4(newColor, opacity);'
            )
        }
        
        return material
    }

    static newTextureShader(customOptions = {}) {

        // get uniforms from options
        const options = ShaderFactory.#getDefaultOptions(customOptions)
        const customUniforms = ShaderFactory.#getCustomUniforms(options)

        // adjust vertex shader
        const vertexShader = ShaderFactory.#getVertexDeclaration() +
        `
            varying vec2 vUv;

            void main() {
        ` + 
        ShaderFactory.#getVertexFunction() +
        `
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `

        // adjust fragment shader
        const fragementShader = ShaderFactory.#getFragmentDeclaration() +
        `
            varying vec2 vUv;

            void main() {
                vec3 newColor = vec3(vUv, 0.0);
        ` +
        ShaderFactory.#getFragmentFunction() +
        `
                gl_FragColor = vec4(newColor, opacity);
            }
        `
    
        const material = new THREE.ShaderMaterial({
            uniforms: customUniforms,
            vertexShader: vertexShader,
            fragmentShader: fragementShader,
            transparent: true 
        })

        material.customUniforms = customUniforms

        return material
    }

    static newNormalsShader(customOptions = {}) {

        // get uniforms from options
        const options = ShaderFactory.#getDefaultOptions(customOptions)
        const customUniforms = ShaderFactory.#getCustomUniforms(options)

        // adjust vertex shader
        const vertexShader = ShaderFactory.#getVertexDeclaration() +
        `
            varying vec3 vNormal;

            void main() {
        ` +
        ShaderFactory.#getVertexFunction() +
        `
                vNormal = normal;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `

        // adjust fragment shader
        const fragementShader = ShaderFactory.#getFragmentDeclaration() +
        `
            varying vec3 vNormal;

            void main() {
                vec3 newColor = normalize(vNormal) * 0.5 + 0.5;
        ` +
        ShaderFactory.#getFragmentFunction() +
        `
                gl_FragColor = vec4(newColor, opacity);
            }
        `
    
        const material = new THREE.ShaderMaterial({
            uniforms: customUniforms,
            vertexShader: vertexShader,
            fragmentShader: fragementShader,
            transparent: true 
        })

        material.customUniforms = customUniforms

        return material
    }
    
    static newDepthShader(customOptions = {}) {

        // get uniforms from options
        const options = ShaderFactory.#getDefaultOptions({
            cameraNear: 0,
            cameraFar: 10,
            ...customOptions
        })
        let customUniforms = ShaderFactory.#getCustomUniforms(options);
        customUniforms = {
            ...customUniforms,
            cameraNear: { value: options.cameraNear }, 
            cameraFar: { value: options.cameraFar },
        }

        // adjust vertex shader
        const vertexShader = ShaderFactory.#getVertexDeclaration() +
        `
            void main() {
        ` +
        ShaderFactory.#getVertexFunction() +
        `
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `

        // adjust fragment shader
        const fragementShader = ShaderFactory.#getFragmentDeclaration() +
        `
            uniform float cameraNear;
            uniform float cameraFar;
                        
            void main() {
                float depth = gl_FragCoord.z / gl_FragCoord.w;
                float depthNormalized = (depth - cameraNear) / (cameraFar - cameraNear);
                float invertedDepth = 1.0 - depthNormalized;
                vec3 newColor = vec3(invertedDepth);
        ` + ShaderFactory.#getFragmentFunction() +
        `
                gl_FragColor = vec4(newColor, opacity);
            }
        `
    
        const material = new THREE.ShaderMaterial({
            uniforms: customUniforms,
            vertexShader: vertexShader,
            fragmentShader: fragementShader,
            transparent: true 
        })

        material.customUniforms = customUniforms

        return material
    }


    //// SHADER HELPER (PRIVATE)

    static #getDefaultOptions(options = {}) {
        return {
            showHover: true,
            showSelection: true, 
            showSets: false,
            hoverIntensity: 0.8,
            selectIntensity: 1.0,
            maximumSets: 8,
            time: 0,
            opacity: 1,
            hoverColor: new THREE.Color(store.getters['app/getVuetify'].colors.color1), 
            selectColor: new THREE.Color(store.getters['app/getVuetify'].colors.color2),
            ...options
        }
    }

    static #getCustomUniforms(options) {
        return {
            showHover: { value: options.showHover },
            showSelection: { value: options.showSelection }, 
            showSets: { value: options.showSets },
            hoverIntensity: { value: options.hoverIntensity },
            selectIntensity: { value: options.selectIntensity },
            maximumSets: { value: options.maximumSets },
            time: { value: options.time },
            opacity: { value: options.opacity },
            hoverColor: { value: options.hoverColor },
            selectColor: { value: options.selectColor }
        }
    }

    static #getVertexDeclaration() {
        return `
        // shader input
        attribute float state;

        // shader output
        varying float vState;
        varying float vHovered;
        varying float vSelected;
        flat out int vSet;
        `
    }

    static #getVertexFunction() {
        return `
        vState = state;
        vSet = int(abs(state) / 2.0);
        vHovered = state < 0.0 ? 1.0 : 0.0;
        vSelected = mod(abs(vState), 2.0) == 1.0 ? 1.0 : 0.0; 
        `
    }

    static #getFragmentDeclaration() {
        return `
        // shader input
        uniform bool showHover;
        uniform bool showSelection;
        uniform bool showSets;
        uniform float hoverIntensity;
        uniform float selectIntensity;
        uniform float maximumSets;
        uniform float time;
        uniform float opacity;
        uniform vec3 hoverColor;
        uniform vec3 selectColor;
        
        // shader output
        varying float vState;
        varying float vHovered;
        varying float vSelected;
        flat in int vSet;

        vec3 hueToRGB(float hue) {
            float r = abs(hue * 6.0 - 3.0) - 1.0;
            float g = 2.0 - abs(hue * 6.0 - 2.0);
            float b = 2.0 - abs(hue * 6.0 - 4.0);
            return clamp(vec3(r, g, b), 0.0, 1.0);
        }
        `
    }

    // requires vec3 named newColor for in/output
    static #getFragmentFunction() {
        return `
        // visualize sets
        if (showSets && vSet > 0) {
            newColor = hueToRGB(mod(float(vSet), maximumSets) / maximumSets);
        }

        // select
        if (showSelection && vSelected > 0.99) {
            float value = selectIntensity;
            newColor = mix(newColor, selectColor, value);
        }

        // hover
        if (showHover && vHovered > 0.99) {
            float pulse = ((sin(time * 4.0) + 1.0) * 0.25) + 0.5;
            float value = hoverIntensity * pulse;
            newColor = mix(newColor, hoverColor, value);
        }
        `
    }


    //// SHADER STATES
    
    // used as an attribute for our custom shader
    // idea: encode multiple values in one float to improve performance
    // why? because a float is the smallest type accepted by shader
    // ---
    // selected: true/false 
    // hovered: true/false
    // set: 0 (disabled) / >=1 (index of set)


    static encodeState(selected, hovered, set) {

        // shift set number to reserve least significant bit for selected
        let state = set << 1
                
        // set the least significant bit if selected
        if (selected) state |= 1
                
        // make the number negative if hovered
        if (hovered) state = -state
                
        // return state
        return state
    }

    static decodeState(state) {

        let hovered = this.isStateHovered(state)
        let selected = this.isStateSelected(state)

        // shift back to get the set value
        let set = Math.abs(state) >> 1
        return {
            selected,
            hovered,
            set
        }
    }

    static isStateHovered(state) {
        // check sign to determine if hovered
        return state < 0
    }

    static isStateSelected(state) {
        // check least significant bit for selection
        return (Math.abs(state) & 1) === 1
    }

    static changeOrToggleStateSelected(attribute, index, selected) {
                
        // get the current state
        let state = attribute.getX(index)
        let absState = Math.abs(state)
        let currentState = (absState & 1) === 1

        // toggle if 'selected' is not provided
        let newState
        if (typeof selected === "boolean")
            newState = selected
        else
            newState = !currentState

        //console.log('change index: ' + index + ' to value: ' + newState)

        // encode the new state
        let set = absState >> 1 
        absState = (set << 1) | (newState ? 1 : 0)
        state = state < 0 ? -absState : absState

        // update the attribute
        attribute.setX(index, state)
    }

    static changeStateHovered(attribute, index, hovered) {
        let state = attribute.getX(index)
        let absState = Math.abs(state)
        state = hovered ? -absState : absState
        attribute.setX(index, state)
    }
}