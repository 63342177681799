import * as THREE from 'three'
import { AbstractMaterial } from '@/classes/data/material/AbstractMaterial'
import { ShaderFactory } from '@/classes/factory/ShaderFactory'


/*
 * Vertex Color Material Class
 */
export class ColorVertexMaterial extends AbstractMaterial
{
    // material type name
    static typeName()
    {
        return 'Vertex Color'
    }
    
    // material type description
    static typeDescription()
    {
        return 'Material using multiple colors'
    }
    
    // material type generator
    static typeGenerator()
    {
        return 'material-color-vertex'
    }

    static typePreviewLight()
    {
        return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAAAXNSR0IArs4c6QAABQVJREFUeF7tnUFu2zAQRZMbZZEjZeEjZZEjZeEbtZigalXBskTyz3AoPgOBDJQUaf43nzOUm7ze7/dfL7ymXYFXAJhW+58PDgBz6w8Ak+sPAABAEjg1A+QAU8tPEji5/AAAAJwDzM0AOcDc+lMGTq4/AAAA5wBTM0AOMLX8lIGTyz8JAN/f3z9Cr6/L+4WA9/f3n7d2Xb+/OiGX2gJM1Edit4h4dTAuAcDn5+eL/US+DIzb7fbXLSLHVo41LABLtEcLv1380UEYDoBF8N7CXwWEYQDoYfM1VjuaIwwBwCjir4ExEL6+vmoYCu2TGoARhd+qZxAslUSosicHSwvAFcRfNLBqwX4yvtIBcCXht1tCxrIxFQBXFX8NQjY3SAGA1fQm/vZ4NqNlKuaUqVLoDsAMUb8HTQY36ArAzOJnSRC7AYD4/3yhpxN0AcD2+o+PD8V2epl79DovCAcA8feZ7QFBKACIf2xY0RCEAYD4x+IvLSIhCAEA8c+LHw2BOwCIXy5+JATuALy9vdWvAD1f7ve76yq4AkCt366d9xmBKwBEfzsAdgdPF3ADgOjXiG938XQBNwCIfh0Ani7gAgCZv1Z8TxdwAcDO+Wd5tq+Xev+OHrmAHACi3w8Jj1xADgDR7weARy4gBYDo9xXfIxeQAkD0+wOgdgEZAER/jPhqF5ABQPTHAaB0ARkAHPxMDAD2Hyu+jab60ojEAQAgHgDVmYAEAB78TA4ACWA8AKrfPyBxAACIB0BVCUgAoAKYGAASwD7iqyqBZgcAgH4AKCqBZgCoACYHgAQQAPj2TycGFKVg8xZABdBJ/T/Dtn5NDAD66tc8OgA0L+HYNwCAsfVrnn13AKgCmjWsvkGKJJBzgGr9mjsCQPMSjn0DTgLH1q959opvBTWXgTwLaNax+gYpALDZcxhUrWFTx9YKwAZvdgAAaNKwurMiAZQBQClYrWN1x1QAUApW61jdUVEByBwAAKp1rO6oSABlAFAJVOtY3TEVACSC1TpWd1RUADIHAIBqHas6qhJAKQDkAVVaVnVS2b8UAFygSsviTsrolwOACxTrWdxBGf1yAHCBYj2LOqij3wUAXKBI06LG6uh3AQAXKNL0dGOP6HcDABc4revphh7R7wYALnBa19MNVQc/2wElj4MffQpc4LS2hw1VD34eDeQGAC5wqOvpBl7R77oF2M1xgdMa7zb0jH53AHCBdgA8oz8EAB4V10PglfmvZ+SaAywDAUE5BBHihzgAEOQVPxQAGwwnOIYhKvKXmYRsAeuPDQT7EESLH+4AbAe5xO8GAGcE/4PgXes/23jCt4D1ZDgo8v2roMcZh+i/hp0ZaK/NzBD0jPxuSeAjECwxNBBm+WOT9mzfxLdr71fXLWD74WdwgwxRH34SWEL5ld0gm/hdq4AjKK7kBhmFT5UD7MFwhUOjzOKndoA1FCP+/oHswg/hANsj5FEqhVHEH8YBRgDBRLfXcj3KcbL8e6oysGRRslQLSz2foaYvWb/htoBniWKPrWEkm0/7LKCG2Gd9lpNEu67ft4yzRPb6Omq0P1qHYbeAElHPgGGiPhK7ZJwR204BwIjCRM0ZAKJWOuk4AJBUmKhpAUDUSicdBwCSChM1LQCIWumk4wBAUmGipgUAUSuddBwASCpM1LQAIGqlk44DAEmFiZoWAEStdNJxACCpMFHTAoColU46DgAkFSZqWgAQtdJJx/kNfwnvLkmijNAAAAAASUVORK5CYII="
    }

    static typePreviewDark()
    {
        return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAAAXNSR0IArs4c6QAABQRJREFUeF7tnTFv2zAQhZMpWzZv/v+/LFu2bJlaXFC1qmBZIvnueBQ/A4EMlBRpvu8e7yg3eb3f779eeE27Aq8AMK32Px8cAObWHwAm1x8AAIAkcGoGyAGmlp8kcHL5AQAAOAeYmwFygLn1pwycXH8AAADOAaZmgBxgavkpAyeXfxIAvr+/f4ReX5f3CwFvb28/b+26fn91Qi61BZioj8RuEfHqYFwCgK+vrxf7iXwZGO/v73/dInJs5VjDArBEe7Tw28UfHYThAFgE7y38VUAYBoAeNl9jtaM5whAAjCL+GhgD4Xa71TAU2ic1ACMKv1XPIFgqiVBlTw6WFoAriL9oYNWC/WR8pQPgSsJvt4SMZWMqAK4q/hqEbG6QAgCr6U387fFsRstUzClTpdAdgBmifg+aDG7QFYCZxc+SIHYDAPH/+UJPJ+gCgO31n5+fiu30MvfodV4QDgDi7zPbA4JQABD/2LCiIQgDAPGPxV9aREIQAgDinxc/GgJ3ABC/XPxICNwB+Pj4qF8Ber7c73fXVXAFgFq/XTvvMwJXAIj+dgDsDp4u4AYA0a8R3+7i6QJuABD9OgA8XcAFADJ/rfieLuACgJ3zz/JsXy/1/h09cgE5AES/HxIeuYAcAKLfDwCPXEAKANHvK75HLiAFgOj3B0DtAjIAiP4Y8dUuIAOA6I8DQOkCMgA4+JkYAOw/VnwbTfWlEYkDAEA8AKozAQkAPPiZHAASwHgAVL9/QOIAABAPgKoSkABABTAxACSAfcRXVQLNDgAA/QBQVALNAFABTA4ACSAA8O2fTgwoSsHmLYAKoJP6f4Zt/ZoYAPTVr3l0AGhewrFvAABj69c8++4AUAU0a1h9gxRJIOcA1fo1dwSA5iUc+wacBI6tX/PsFd8Kai4DeRbQrGP1DVIAYLPnMKhaw6aOrRWADd7sAADQpGF1Z0UCKAOAUrBax+qOqQCgFKzWsbqjogKQOQAAVOtY3VGRAMoAoBKo1rG6YyoASASrdazuqKgAZA4AANU6VnVUJYBSAMgDqrSs6qSyfykAuECVlsWdlNEvBwAXKNazuIMy+uUA4ALFehZ1UEe/CwC4QJGmRY3V0e8CAC5QpOnpxh7R7wYALnBa19MNPaLfDQBc4LSupxuqDn62A0oeBz/6FLjAaW0PG6oe/DwayA0AXOBQ19MNvKLfdQuwm+MCpzXebegZ/e4A4ALtAHhGfwgAPCquh8Ar81/PyDUHWAYCgnIIIsQPcQAgyCt+KAA2GE5wDENU5C8zCdkC1h8bCPYhiBY/3AHYDnKJ3w0Azgj+B8G71n+28YRvAevJcFDk+1dBjzMO0X8NOzPQXpuZIegZ+d2SwEcgWGJoIMzyxybt2b6Jb9fer65bwPbDz+AGGaI+/CSwhPIru0E28btWAUdQXMkNMgqfKgfYg+EKh0aZxU/tAGsoRvz9A9mFH8IBtkfIo1QKo4g/jAOMAIKJbq/lepTjZPn3VGVgyaJkqRaWej5DTV+yfsNtAc8SxR5bw0g2n/ZZQA2xz/osJ4l2Xb9vGWeJ7PV11Gh/tA7DbgElop4Bw0R9JHbJOCO2nQKAEYWJmjMARK100nEAIKkwUdMCgKiVTjoOACQVJmpaABC10knHAYCkwkRNCwCiVjrpOACQVJioaQFA1EonHQcAkgoTNS0AiFrppOMAQFJhoqYFAFErnXQcAEgqTNS0ACBqpZOO8xtcTuEf90PLqgAAAABJRU5ErkJggg=="
    }
    
    static newDefaultInstance(mesh)
    {
        // create empty vertexColors
        const count = mesh.getActiveGeometry().three().attributes.position.count
        const component = new Float32Array(count * 3)
        return ColorVertexMaterial.newInstance(mesh, component)
    }

    static newInstance(mesh, component)
    {
        // create new material instance
        const newInstance = new ColorVertexMaterial(
            mesh,
            "New Material",
            component,
            true
        )

        // add instance to mesh
        if (mesh) mesh.addMaterial(newInstance)

        // return new instance
        return newInstance
    }

    // class constructor
    constructor(
        mesh,
        name,
        vertexColors,
        locked=false,
        opacity=1,
        update=true)
    {
        // call parent class constructor
        super(mesh, name, locked, opacity, false)

        // initialize state
        this.nonreactive.state = {
            vertexColors: new Float32Array(vertexColors)
        }

        //console.log(vertexColors)

        // initialize three.js material
        this.nonreactive.three = ShaderFactory.newVertexColorShader()

        // apply state
        if (update) this.update(false)
    }

    // update material
    update(propagate=true)
    {
        // get references
        const state = this.nonreactive.state
        const three = this.nonreactive.three
        const geometry = this.mesh.getActiveGeometry()
        const threeGeometry = geometry.three()

        // material-independent three.js config
        three.side = THREE.DoubleSide
        three.transparent = true
        three.opacity = this.nonreactive.opacity

        // set color attribute of geometry
        const colorAttribute = new THREE.BufferAttribute(
            state.vertexColors, 
            3
        )
        threeGeometry.setAttribute('color', colorAttribute)

        // trigger update of parent class
        super.update(propagate)
    }

    clone(mesh)
    {
        const materialClone = new ColorVertexMaterial(
            mesh,
            "New Clone",
            this.nonreactive.state.vertexColor,
            false,
            this.nonreactive.opacity
        )

        mesh.addMaterial(materialClone)

        return materialClone
    }
}