//// MATH

export function round(value, decimals) 
{
    const factor = Math.pow(10, decimals)
    return Math.round(value * factor) / factor
}

// calculate Euclidean distance
export function calcEuclidean(vector1, vector2) 
{
    // at least two dimensions
    let distanceSquared = Math.pow(vector1.x - vector2.x, 2) +
        Math.pow(vector1.y - vector2.y, 2)

    // add third dimension if needed
    if (typeof vector1.z !== 'undefined' && typeof vector2.z !== 'undefined')
        distanceSquared += Math.pow(vector1.z - vector2.z, 2)

    // return the square root
    return Math.sqrt(distanceSquared)
}