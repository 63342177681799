// vuex.js state of modulator (user) interface


//// CONSTANTS

// window
const WINDOW_IMPORT = 'import'
const WINDOW_BASE = 'base'
const WINDOW_EXPORT = 'export'
const WINDOW_DEFAULT = WINDOW_BASE

// page (positive numbers are reserved for meshes)
const PAGE_PREVIEW = -1
const PAGE_MODEL = 0
const PAGE_DEFAULT = PAGE_PREVIEW

// tab
const TAB_GENERAL = 'general'
const TAB_TOOLS = 'tools'
const TAB_GEOMETRY = 'geometry'
const TAB_MATERIAL = 'material'
const TAB_DEFAULT = TAB_GENERAL

// view
const VIEW_DEFAULT = 0

// filter 
const FILTER_DEFAULT = 0


//// TABS

// general tab
const createTabGeneral = () => {
    return {
        id: TAB_GENERAL,
        view: VIEW_DEFAULT
    }
}

// tool tab
const createTabTools = () => {
    return {
        id: TAB_TOOLS,
        view: VIEW_DEFAULT
    }
}

// geometry tab
const createTabGeometry = () => {
    return {
        id: TAB_GEOMETRY,
        view: VIEW_DEFAULT,
        filter: FILTER_DEFAULT
    }
}

// material tab
const createTabMaterial = () => {
    return {
        id: TAB_MATERIAL,
        view: VIEW_DEFAULT,
        filter: FILTER_DEFAULT
    }
}


//// PAGES

// preview page
const createPagePreview = () => {
    return {
        id: PAGE_PREVIEW,
        tabs: null,
        activeTab: null
    }
}

// model page
const createPageModel = () => {
    return {
        id: PAGE_MODEL,
        tabs: [
            createTabGeneral(),
            createTabTools()
        ],
        activeTab: TAB_GENERAL
    }
}

// mesh page
const createPageMesh = (pageId) => {
    return {
        id: pageId,
        tabs: [
            createTabGeneral(),
            createTabGeometry(),
            createTabMaterial()
        ],
        activeTab: TAB_DEFAULT/*,
        camera: null*/
    }
}

// default pages
const createPagesDefault = () => {
    return [
        createPagePreview(),
        createPageModel()
    ]
}


//// STATE

const createStateDefault = () => {
    return {
        activeWindow: WINDOW_DEFAULT,
        pages: createPagesDefault(),
        activePage: PAGE_PREVIEW
    }
}


//// VUEX STORE

// vuex store module for modulator interface
export const modulator = {
    namespaced: true,
    state: createStateDefault(),
    mutations: {
        RESET_INTERFACE(state) 
        {
            Object.assign(state, createStateDefault())
        },
        SET_ACTIVE_WINDOW(state, window) 
        {
            state.activeWindow = window
        },
        ADD_PAGE(state, pageId) 
        {
            // try to find page with the id
            let existingPage = state.pages.find(p => p.id === pageId)

            // if it does not exist, create a new page
            if (!existingPage)
            {
                // create new page
                const newPage = createPageMesh(pageId)

                // add new page to array
                state.pages.push(newPage)
            }
        },
        // pages are not meant to be removed 
        // (at least not in the new UI -- for now)
        REMOVE_PAGE(state, pageId) 
        {
            // preview page cannot be removed
            if (pageId === PAGE_PREVIEW) return
            // model page cannot be removed
            if (pageId === PAGE_MODEL) return
            
            // if the page to be removed is active
            if (pageId === state.activePage) state.activePage = PAGE_DEFAULT
            
            // remove page
            state.pages = state.pages.filter(p => p.id !== pageId)
        },
        SET_ACTIVE_PAGE(state, pageId) 
        {
            state.activePage = pageId
        },
        SET_ACTIVE_TAB(state, { pageId, tabId }) 
        {
            const page = state.pages.find(p => p.id === pageId)
            if (page) page.activeTab = tabId
        },
        SET_ACTIVE_VIEW(state, { pageId, tabId, viewId }) 
        {
            // find relevant page
            const page = state.pages.find(p => p.id === pageId)

            // only if page exists and contains tabs
            if (page && page.tabs) 
            {
                // find relevant tab
                const tab = page.tabs.find(t => t.id === tabId)

                // only if tab exists
                if (tab) tab.view = viewId
            }
        },
        SET_ACTIVE_FILTER(state, { pageId, tabId, filter }) 
        {
            const page = state.pages.find(p => p.id === pageId)

            if (page && page.tabs) 
            {
                const tab = page.tabs.find(t => t.id === tabId)
                if (tab) tab.filter = filter
            }
        }
    },
    getters: {
        keyWindowDefault: () => WINDOW_DEFAULT,
        keyWindowImport: () => WINDOW_IMPORT,
        keyWindowBase: () => WINDOW_BASE,
        keyWindowExport: () => WINDOW_EXPORT,
        keyPageDefault: () => PAGE_DEFAULT,
        keyPagePreview: () => PAGE_PREVIEW,
        keyPageModel: () => PAGE_MODEL,
        keyTabDefault: () => TAB_DEFAULT,
        keyTabGeneral: () => TAB_GENERAL,
        keyTabTools: () => TAB_TOOLS,
        keyTabGeometry: () => TAB_GEOMETRY,
        keyTabMaterial: () => TAB_MATERIAL,
        keyViewDefault: () => VIEW_DEFAULT,
        getActiveWindow: state => state.activeWindow,
        getPages: state => state.pages,
        getPageObject: state => pageId => state.pages.find(p => p.id === pageId),
        getActivePage: state => state.activePage,
        getActivePageObject: (state, getters) => getters.getPageObject(state.activePage),
        getTabs: (state, getters) => pageId => {
            const page = getters.getPageObject(pageId)
            if (page) return page.tabs
            return null
        },
        getTabObject: (state, getters) => (pageId, tabId) => {
            const page = getters.getPageObject(pageId)
            if (!page || !page.tabs) return null
            return page.tabs.find(t => t.id === tabId)
        },
        getActiveTab: (state, getters) => getters.getActivePageObject?.activeTab,
        getActiveTabObject: (state, getters) => {
            return getters.getTabObject(state.activePage, getters.getActiveTab)
        },
        getActiveView: (state, getters) => getters.getActiveTabObject?.view,
        getActiveFilter: (state, getters) => getters.getActiveTabObject?.filter,
        isWindowDefault: state => state.activeWindow === WINDOW_DEFAULT,
        isWindowImport: state => state.activeWindow === WINDOW_IMPORT,
        isWindowBase: state => state.activeWindow === WINDOW_BASE,
        isWindowExport: state => state.activeWindow === WINDOW_EXPORT,
        isPageDefault: state => state.activePage === PAGE_DEFAULT,
        isPagePreview: state => state.activePage === PAGE_PREVIEW,
        isPageModel: state => state.activePage === PAGE_MODEL,
        isPageMesh: state => state.activePage > 0,
        isTabDefault: (state, getters) => getters.getActiveTab === TAB_DEFAULT,
        isTabGeneral: (state, getters) => getters.getActiveTab === TAB_GENERAL,
        isTabTools: (state, getters) => getters.getActiveTab === TAB_TOOLS,
        isTabGeometry: (state, getters) => getters.getActiveTab === TAB_GEOMETRY,
        isTabMaterial: (state, getters) => getters.getActiveTab === TAB_MATERIAL,
        isViewDefault: (state, getters) => getters.getActiveView === VIEW_DEFAULT,
        isFilterDefault: (state, getters) => getters.getActiveFilter === FILTER_DEFAULT
    },
    actions: {
        resetInterface({ commit }) 
        {
            commit('RESET_INTERFACE')
        },
        setActiveWindow({ commit }, window) 
        {
            commit('SET_ACTIVE_WINDOW', window)
        },
        setActiveWindowToDefault({ commit }) 
        {
            commit('SET_ACTIVE_WINDOW', WINDOW_DEFAULT)
        },
        setActiveWindowToImport({ commit }) 
        {
            commit('SET_ACTIVE_WINDOW', WINDOW_IMPORT)
        },
        setActiveWindowToBase({ commit }) 
        {
            commit('SET_ACTIVE_WINDOW', WINDOW_BASE)
        },
        setActiveWindowToExport({ commit }) 
        {
            commit('SET_ACTIVE_WINDOW', WINDOW_EXPORT)
        },
        addPage({ commit }, pageId) 
        {
            commit('ADD_PAGE', pageId)
        },
        removePage({ commit }, pageId) 
        {
            commit('REMOVE_PAGE', pageId)
        },
        setActivePage({ commit, getters }, pageId) 
        {
            // try to get page
            const page = getters.getPageObject(pageId)

            // if page does not exist
            if (page == null) {
                // create page
                commit('ADD_PAGE', pageId)
            }

            // set page active
            commit('SET_ACTIVE_PAGE', pageId)
        },
        setActivePageToDefault({ dispatch }) 
        {
            dispatch('setActivePage', PAGE_DEFAULT)
        },
        setActivePageToPreview({ dispatch }) 
        {
            dispatch('setActivePage', PAGE_PREVIEW)
        },
        setActivePageToModel({ dispatch }) 
        {
            dispatch('setActivePage', PAGE_MODEL)
        },
        // payload
        // requires: tabId
        // optional: pageId (otherwise active is used)
        setActiveTab({ commit, getters }, payload) 
        {
            let pageId = payload.pageId
            if (pageId == null) pageId = getters.getActivePage

            commit('SET_ACTIVE_TAB', { pageId, tabId: payload.tabId })
        },
        setActiveTabToGeneral({ dispatch }) 
        {
            dispatch('setActiveTab', TAB_GENERAL)
        },
        setActiveTabToGeometry({ dispatch }) 
        {
            dispatch('setActiveTab', TAB_GEOMETRY)
        },
        setActiveTabToMaterial({ dispatch }) 
        {
            dispatch('setActiveTab', TAB_MATERIAL)
        },
        // payload
        // requires: viewId
        // optional: pageId, tabId (otherwise active is used)
        setActiveView({ commit, getters }, payload) 
        {
            let pageId = payload.pageId
            let tabId = payload.tabId
            if (pageId == null) pageId = getters.getActivePage
            if (tabId == null) tabId = getters.getActiveTab
            commit('SET_ACTIVE_VIEW', { pageId, tabId, viewId: payload.viewId })
        },
        setActiveViewToDefault({ dispatch }) 
        {
            dispatch('setActiveView', { viewId: VIEW_DEFAULT })
        },
        // payload
        // requires: filter
        // optional: pageId, tabId (otherwise active is used)
        setActiveFilter({ commit, getters }, payload) 
        {
            let pageId = payload.pageId
            let tabId = payload.tabId
            if (pageId == null) pageId = getters.getActivePage
            if (tabId == null) tabId = getters.getActiveTab
            commit('SET_ACTIVE_FILTER', { pageId, tabId, filter: payload.filter })
        },
        setActiveFilterToDefault({ dispatch }) 
        {
            dispatch('setActiveFilter', { filter: FILTER_DEFAULT })
        }
    }
}