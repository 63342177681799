/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */


//// Styles
import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'


//// Composables

import { createVuetify } from 'vuetify'
import { aliases, fa } from 'vuetify/iconsets/fa'



// light theme
const lightTheme = {
	dark: false,
	colors: {
		darkbase: '#FFFFFF',
		lightbase: '#F0F0F0',
		background: '#E6E6E6',
		darkground: '#DDD',
		lightground: '#D0D0D0',
		surface: '#C0C0C0',
		viewer: '#CACACA',
		shadow1: '#BBB',
		shadow2: '#AAA',
		primary: '#444',
		secondary: '#777',
		accent: '#999',
		invert: '#000',
		success: '#7b7',
		warning: '#b77'
	}
}

// dark theme
const darkTheme = {
	dark: true,
	colors: {
		darkbase: '#000000',
		lightbase: '#1A1A1A',
		background: '#3A3A3A',
		darkground: '#484848',
		lightground: '#555555',
		surface: '#5F5F5F',
		viewer: '#5A5A5A',
		shadow1: '#222',
		shadow2: '#444',
		primary: '#EEE',
		secondary: '#888',
		accent: '#666',
		invert: '#FFF',
		success: '#6a6',
		warning: '#a66'
	}
}


//// VUETIFY

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
const vuetify = createVuetify({
	theme: {
		defaultTheme: 'darkTheme',
		themes: {
			lightTheme,
			darkTheme
		}
	},
	icons: {
		defaultSet: 'fa',
		aliases,
		sets: {
			fa,
		},
	}
})


//// Accents

// all accent colors
const accentOptions = {
	0: {
		name: 'soft blue',
		light: {
			color1: '#4f6fad',
			color2: '#3e5787'
		},
		dark: {
			color1: '#6087d3',
			color2: '#4f6fad'
		}
	},
	1: {
		name: 'royal purple',
		light: {
			color1: '#9853ff',
			color2: '#7942cc'
		},
		dark: {
			color1: '#721bd7',
			color2: '#5614a3'
		}
	},
	2: {
		name: 'ocean teel',
		light: {
			color1: '#6cabd2',
			color2: '#51819f'
		},
		dark: {
			color1: '#4eaad4',
			color2: '#3b81a1'
		}
	},
	3: {
		name: 'forest green',
		light: {
			color1: '#8bcc6e',
			color2: '#78b25e'
		},
		dark: {
			color1: '#448833',
			color2: '#2a551f'
		}
	}
}

// method to update accent colors
const updateAccentColors = (accentIndex) => {

	// update accent colors
	const selectedAccent = accentOptions[accentIndex]

	// update light theme
	vuetify.theme.themes._value.lightTheme.colors.color1 = selectedAccent.light.color1
	vuetify.theme.themes._value.lightTheme.colors.color2 = selectedAccent.light.color2

	// update dark theme
	vuetify.theme.themes._value.darkTheme.colors.color1 = selectedAccent.dark.color1
	vuetify.theme.themes._value.darkTheme.colors.color2 = selectedAccent.dark.color2
}


//// Exports

export default vuetify
export { accentOptions, updateAccentColors }