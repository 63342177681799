/**
 * Infill Class
 */
export class Infill
{
    //// PROPERTIES

    // STATIC
    static lastId = 0   // remember last id 

    // DYNAMIC
    // [side]
    // [position]
    // [dimensions]
    // [radii]


    //// METHODS

    // class constructor
    constructor(side, position) 
    {
        // static properties
        this.id = ++Infill.lastId

        // dynamic properties
        this.side = side
        this.position = position
        this.dimensions = {
            base: 100,
            height: 100
        }
        this.radii = {
            corner: 15,
            edge: 15
        }
    }
}