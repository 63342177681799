import * as THREE from 'three'
import { BasicGeometry } from '@/classes/data/geometry/BasicGeometry'

export class OptimizedGeometry extends BasicGeometry
{
    //// METHODS

    // geometry type name
    static typeName()
    {
        return 'Optimized'
    }
    
    // geometry type description
    static typeDescription()
    {
        return 'Recalculated texture coordinates'
    }
    
    // geometry type generator
    static typeGenerator()
    {
        return null
    }

    // class constructor
    constructor(
        mesh, 
        name, 
        indicesAttribute,
        positionAttribute,
        textureAttribute,
        normalsAttribute,
        locked=false,
        update=true)
    {
        super(
            mesh,
            name,
            indicesAttribute,
            positionAttribute,
            textureAttribute,
            normalsAttribute,
            locked,
            false
        )

        if (update) this.update(false)
    }

    update(propagate=true)
    {
        const position = this.three().attributes.position

        // get count
        const count = position.count

        // create a new array to store UV coordinates
        const texture = new Float32Array(count * 2)

        // create a new array to store normals
        const normals = new Float32Array(count * 3)



        // helper function: round number to precision (number of decimal places)
        function roundToPrecision(num, precision) 
        {
            let factor = Math.pow(10, precision)
            return Math.round(num * factor) / factor
        }

        // helper function: calculate normal of triangle
        function computeNormal(p1, p2, p3) 
        {
            // calculate normal
            let v1 = new THREE.Vector3().subVectors(p2, p1)
            let v2 = new THREE.Vector3().subVectors(p3, p1)
            let normal = new THREE.Vector3().crossVectors(v1, v2).normalize()

            // return normal
            return normal
        }

        // helper function: calculate key from normal
        function computeKey(normal, precision=1)
        {
            // round each component of the normal to the specified precision
            normal.x = roundToPrecision(normal.x, precision)
            normal.y = roundToPrecision(normal.y, precision)
            normal.z = roundToPrecision(normal.z, precision)

            // return normal
            return normal.toArray().toString()
        }



        // intialize variables to store the min and max coordinates
        let minX = +Infinity, minY = +Infinity, minZ = +Infinity
        let maxX = -Infinity, maxY = -Infinity, maxZ = -Infinity

        // grouping triangles with the same normal
        let groupedTriangles = {}
        for (let i = 0; i < count; i += 3) 
        {
            // each vertex consists of 3 positions (x, y, z)
            //let idx = i * 3; // index in the position array
            //let p1 = new THREE.Vector3(position[idx], position[idx + 1], position[idx + 2])
            //let p2 = new THREE.Vector3(position[idx + 3], position[idx + 4], position[idx + 5])
            //let p3 = new THREE.Vector3(position[idx + 6], position[idx + 7], position[idx + 8])

            let p1 = new THREE.Vector3(
                position.getX(i), 
                position.getY(i), 
                position.getZ(i))
            let p2 = new THREE.Vector3(
                position.getX(i + 1), 
                position.getY(i + 1), 
                position.getZ(i + 1))
            let p3 = new THREE.Vector3(
                position.getX(i + 2), 
                position.getY(i + 2), 
                position.getZ(i + 2))

            // array of vertices
            let vertices = [p1, p2, p3]

            // update min and max coordinates
            vertices.forEach(vertex => {
                // update min values
                minX = Math.min(minX, vertex.x)
                minY = Math.min(minY, vertex.y)
                minZ = Math.min(minZ, vertex.z)
                // update max values
                maxX = Math.max(maxX, vertex.x)
                maxY = Math.max(maxY, vertex.y)
                maxZ = Math.max(maxZ, vertex.z)
            })

            // compute normal for triangle
            let normal = computeNormal(p1, p2, p3)

            // calculate key from normal
            let key = computeKey(normal)

            // if normal does not exist yet, add an empty array
            if (!groupedTriangles[key]) 
                groupedTriangles[key] = []

            // add triangle to array
            groupedTriangles[key].push({
                vertices: [p1, p2, p3],
                normal: normal,
                index: i
            })
        }

        // calculate extent for each group
        for (let key in groupedTriangles) 
        {
            // get current group of triangles
            let group = groupedTriangles[key]

            //// CALCULATE EXTENT AND NORMAL TRANSFORMATION

            // variables to store min and max values in the rotated system
            let minU = +Infinity, minV = +Infinity
            let maxU = -Infinity, maxV = -Infinity

            // iterate through triangles of group
            for (let i = 0; i < group.length; i++)
            {
                // get current triangle
                let triangle = group[i]
        
                // align normal with z-axis
                let alignWithZAxis = new THREE.Matrix4().lookAt(
                    triangle.normal,
                    new THREE.Vector3(0, 0, 0),
                    new THREE.Vector3(0, 1, 0)
                ).invert()

                // iterate through vertices
                triangle.vertices.forEach(vertex => {
                    // apply rotation
                    vertex.applyMatrix4(alignWithZAxis)

                    // update min and max values in the rotated system
                    minU = Math.min(minU, vertex.x)
                    minV = Math.min(minV, vertex.y)
                    maxU = Math.max(maxU, vertex.x)
                    maxV = Math.max(maxV, vertex.y)
                })
            }

            // get min and max across U and V
            const min = Math.min(minX, minY, minZ)
            const max = Math.max(maxX, maxY, maxZ)

            //// CALCULATE NEW UV COORDINATES

            // iterate through triangles of group
            for (let i = 0; i < group.length; i++)
            {
                // get current triangle
                let triangle = group[i]

                // iterate through vertices
                for (let j = 0; j < triangle.vertices.length; j++)
                {
                    // get current vertex
                    let vertex = triangle.vertices[j]

                    // uniformly scale UV coordinates
                    let u = roundToPrecision(((vertex.x - min) / max) * 0.5, 2)
                    let v = roundToPrecision(((vertex.y - min) / max) * 0.5, 2)

                    // store the UV coordinate
                    texture[triangle.index * 2 + j * 2 + 0] = u
                    texture[triangle.index * 2 + j * 2 + 1] = v

                    // store the normals
                    normals[triangle.index * 3 + j * 3 + 0] = triangle.normal.x
                    normals[triangle.index * 3 + j * 3 + 1] = triangle.normal.y
                    normals[triangle.index * 3 + j * 3 + 2] = triangle.normal.z
                }
            }
        }

        const textureAttribute = new THREE.BufferAttribute(texture, 2)
        this.nonreactive.three.setAttribute(
            'uv',
            textureAttribute
        )
    
        const normalsAttribute = new THREE.BufferAttribute(normals, 3)
        this.nonreactive.three.setAttribute(
            'normals',
            normalsAttribute
        )

        // trigger update of parent class
        super.update(propagate)
    }
}