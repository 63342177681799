import * as THREE from 'three'
import { reactive } from 'vue'

/**
 * Mesh Class
 */
export class Mesh 
{
    // (Mesh) 1 : N (Material)

    
    //// PROPERTIES

    // STATIC
    static lastId = 0   // remember last id 

    // CONSTANT
    // [id] unique identifier for mesh; incremented for each instantiated object
    // [model] reference to parent model

    // REACTIVE
    // [name] name of the mesh 
    // [version] internal counter to track current iteration of mesh (for external listeners)
    // [ready] flag indicating if the mesh is ready (in a stable state; no more loading in progress)
    // [visible] flag indicating if the mesh should be rendered (or if it is unvisible currently)
    // [geoActive] currently active geometry in array 
    // [matActive] currently active material in array 

    // NONREACTIVE
    // [geometries] array storing all geometries of this mesh
    // [materials] array storing all materials of this mesh
    

    //// METHODS

    // class constructor
    constructor(model, name) 
    {
        // constant properties
        this.id = ++Mesh.lastId
        this.model = model

        // reactive properties
        this.reactive = reactive({
            name: name, 
            version: 0,
            ready: true,
            visible: true,
            position: { x: 0, y: 0, z: 0 },
            rotation: { x: 0, y: 0, z: 0 },
            scale: { x: 1, y: 1, z: 1 },
            geoActive: 0,
            matActive: 0
        })

        // nonreactive properties
        this.nonreactive = {
            geometries: [],
            materials: [],
            three: null
        }

        // initialize three.js mesh
        this.nonreactive.three = new THREE.Mesh()
    }

    // reset mesh
    reset()
    {
        // reset visiblity
        this.reactive.visible = true

        // reset transformations
        this.reactive.position = { x: 0, y: 0, z: 0 }
        this.reactive.rotation = { x: 0, y: 0, z: 0 }
        this.reactive.scale = { x: 1, y: 1, z: 1 }

        // reset geometry
        this.geoActive = 0

        // reset material
        this.matActive = 0

        // mark as updated
        this.update()
    }

    // update mesh
    // propagate: if true, the update gets trigger for the parent model as well
    update(propagate=true) 
    {
        // update three.js mesh
        //this.nonreactive.three.geometry = this.getActiveGeometry().three()
        //this.nonreactive.three.material = this.getActiveMaterial().three()

        // update took place
        this.reactive.version += 1

        // propagate update to parent model
        if(propagate) this.model.update()
    }

    clone(model)
    {
        const meshClone = new Mesh(
            model,
            "New Clone"
        )

        // clone state
        meshClone.reactive.visible = this.reactive.visible
        meshClone.reactive.position = {
            x: this.reactive.position.x,
            y: this.reactive.position.y,
            z: this.reactive.position.z
        }
        meshClone.reactive.rotation = {
            x: this.reactive.rotation.x,
            y: this.reactive.rotation.y,
            z: this.reactive.rotation.z
        }
        meshClone.reactive.scale = {
            x: this.reactive.scale.x,
            y: this.reactive.scale.y,
            z: this.reactive.scale.z
        }

        model.addMesh(meshClone)

        return meshClone
    }

    setName(name)
    {
        this.reactive.name = name
    }

    getName()
    { 
        return this.reactive.name
    }

    // set ready state
    // changes the ready state of the mesh
    setReady(ready)
    {
        this.reactive.ready = ready
    }

    // is mesh ready?
    // checks if the mesh is flagged ready
    isReady()
    {
        return this.reactive.ready
    }

    setVisiblity(visible)
    {
        this.reactive.visible = visible
        this.update()
    }

    isVisible()
    {
        return this.reactive.visible
    }

    // set mesh position
    // specify the translation of the mesh
    setPosition(x=0, y=0, z=0) 
    {
        this.reactive.position.x = x
        this.reactive.position.y = y
        this.reactive.position.z = z
        this.update()
    }

    // get mesh position
    getPosition()
    {
        return this.reactive.position
    }

    // set mesh rotation
    // specify the rotation of the mesh
    setRotation(x=0, y=0, z=0) 
    {
        this.reactive.rotation.x = x
        this.reactive.rotation.y = y
        this.reactive.rotation.z = z
        this.update()
    }

    // get mesh rotation
    getRotation()
    {
        return this.reactive.rotation
    }

    // set mesh scale
    // specify the scale of the mesh
    setScale(x=1, y=1, z=1) 
    {
        this.reactive.scale.x = x
        this.reactive.scale.y = y
        this.reactive.scale.z = z
        this.update()
    }

    // get mesh scale
    getScale()
    {
        return this.reactive.scale
    }

    // get default geometry
    getDefaultGeometry()
    {
        return this.nonreactive.geometries[0]
    }

    // get active geometry
    getActiveGeometry() 
    {
        return this.nonreactive.geometries[this.reactive.geoActive]
    }

    // set active geometry
    setActiveGeometry(id)
    {
        // find the corresponding index
        const index = this.nonreactive.geometries.findIndex(geometry => geometry.id === id)

        // if no index found; abort
        if (index < 0) return null
            
        // set active geometry
        this.reactive.geoActive = index

        // trigger update
        this.update()

        // return activated geometry
        return this.getActiveGeometry()
    }

    // is geometry active?
    isGeometryActive(id)
    {
        // find the corresponding index
        const index = this.nonreactive.geometries.findIndex(geometry => geometry.id === id)

        // compare with active geometry index
        return index === this.reactive.geoActive
    }

    addGeometry(geometry)
    {
        this.nonreactive.geometries.push(geometry)
    }

    // get default Material
    getDefaultMaterial()
    {
        return this.nonreactive.materials[0]
    }

    // get active material
    getActiveMaterial() 
    {
        return this.nonreactive.materials[this.reactive.matActive]
    }

    // set active material
    setActiveMaterial(id)
    {
        // find the corresponding index
        const index = this.nonreactive.materials.findIndex(material => material.id === id)

        // if no index found; abort
        if (index < 0) return null
            
        // set active material
        this.reactive.matActive = index

        // trigger update
        this.update()

        // return activated material
        return this.getActiveMaterial()
    }

    // is material active?
    isMaterialActive(id)
    {
        // find the corresponding index
        const index = this.nonreactive.materials.findIndex(material => material.id === id)

        // compare with active material index
        return index === this.reactive.matActive
    }

    addMaterial(material)
    {
        this.nonreactive.materials.push(material)
    }

    // get the current three.js mesh
    three()
    {
        // update three.js mesh
        this.nonreactive.three.geometry = this.getActiveGeometry().three()
        this.nonreactive.three.material = this.getActiveMaterial().three()

        // return updated mesh
        return this.nonreactive.three
    }

    // mesh summary
    // returns a summary of this mesh
    summary(print=true) 
    {
        // begin message
        let msg = "\n[Mesh Summary]\n"

        // constant properties
        msg += "id: " + this.id + "\n"
        msg += "model: " + this.model.id + "\n"

        // convert vector to string
        const vec2str = (vector) => {
            return vector.x + "," + vector.y + "," + vector.z
        }

        // reactive properties
        msg += "name: " + this.reactive.name + "\n"
        msg += "version: " + this.reactive.version + "\n"
        msg += "ready: " + this.reactive.ready + "\n"
        msg += "visble: " + this.reactive.visible + "\n"
        msg += "position: " + vec2str(this.reactive.position) + "\n"
        msg += "rotation: " + vec2str(this.reactive.rotation) + "\n"
        msg += "scale: " + vec2str(this.reactive.scale) + "\n"
        msg += "active geometry: " + this.reactive.geoActive + "\n"
        msg += "active material: " + this.reactive.matActive + "\n"

        // nonreactive properties
        msg += "geometry count: " + this.nonreactive.geometries.length + "\n"
        msg += "material count: " + this.nonreactive.materials.length + "\n"

        // summary for geometry
        msg += this.getActiveGeometry().summary(false)
    
        // summary for material
        msg += this.getActiveMaterial().summary(false)
    
        // log to console
        if(print) console.log(msg)
    
        return msg
    }
}