import { reactive } from 'vue'
import * as THREE from 'three'

/**
 * Model Class
 */
export class Model 
{
    // (Model) 1 : N (Mesh)


    //// PROPERTIES

    // STATIC
    static lastId = 0   // remember last id 

    // CONSTANT
    // [id] unique identifier for model; incremented for each instantiated model object

    // REACTIVE
    // [name] name of the model
    // [version] internal counter to track current iteration of model (for external listeners)
    // [ready] flag indicating if the model is ready (in a stable state; no more loading in progress)
    // [transform] position, rotation, and scale of model

    // NONREACTIVE
    // [meshes] meshes within the model (ideally representing different parts of the model such as windows, wheels, ...)


    //// METHODS

    // class constructor
    constructor(name) 
    {
        // constant properties
        this.id = ++Model.lastId

        // reactive properties
        this.reactive = reactive({
            name,
            version: 0,
            ready: false,
            position: { x: 0, y: 0, z: 0 },
            rotation: { x: 0, y: 0, z: 0 },
            scale: { x: 1, y: 1, z: 1 }
        })

        // nonreactive properties
        this.nonreactive = {
            meshes: []
        }
    }

    // reset model
    // resets all model values (including all meshes)
    reset()
    {
        // iterate through all meshes
        this.nonreactive.meshes.forEach(mesh => {
            mesh.reset()
        })
    }

    // update model
    // flag the model as updated
    update() 
    {
        this.reactive.version += 1
    }

    getVersion()
    {
        return this.reactive.version
    }

    // set ready state
    // changes the ready state of the model
    setReady(ready)
    {
        this.reactive.ready = ready
    }

    // is model ready?
    // checks if the model is flagged ready
    isReady()
    {
        return this.reactive.ready
    }

    // set model position
    // specify the translation of the model
    setPosition(x=0, y=0, z=0) 
    {
        this.reactive.position.x = x
        this.reactive.position.y = y
        this.reactive.position.z = z
        this.update()
    }

    // get model position
    getPosition()
    {
        return this.reactive.position
    }

    // set model rotation
    // specify the rotation of the model
    setRotation(x=0, y=0, z=0) 
    {
        this.reactive.rotation.x = x
        this.reactive.rotation.y = y
        this.reactive.rotation.z = z
        this.update()
    }

    // get model rotation
    getRotation()
    {
        return this.reactive.rotation
    }

    // set model scale
    // specify the scale of the model
    setScale(x=1, y=1, z=1) 
    {
        this.reactive.scale.x = x
        this.reactive.scale.y = y
        this.reactive.scale.z = z
        this.update()
    }

    // get model scale
    getScale()
    {
        return this.reactive.scale
    }

    hasMeshes()
    {
        return this.nonreactive.meshes?.length > 0
    }

    // get meshes of model
    getMeshes()
    {
        return this.nonreactive.meshes
    }

    // add mesh to model
    addMesh(mesh)
    {
        this.nonreactive.meshes.push(mesh)
        this.update()
    }

    // get mesh by id
    getMesh(id) 
    {
        if(this.nonreactive.meshes === null) return null
        return this.nonreactive.meshes.find(mesh => mesh.id == id)
    }
    
    // get mesh by uuid
    getMeshByUUID(uuid)
    {
        if(this.nonreactive.meshes === null) return null
        return this.reactive.meshes.find(mesh => 
            mesh.three().uuid == uuid)
    }

    // delete mesh from model
    deleteMesh(id)
    {
        // if model does not contain any meshes; abort
        if(this.nonreactive.meshes === null) return

        // find the mesh
        const index = this.nonreactive.meshes.findIndex(mesh => mesh.id === id)

        // if found, remove the mesh
        if (index !== -1) this.nonreactive.meshes.splice(index, 1)

        this.update()
    }

    // automatically adjust the position and scale of the model to fit into the viewer
    autoAdjust() 
    {
        // boundary box of model
        const modelBox = new THREE.Box3()

        // iterate through meshes
        this.nonreactive.meshes.forEach(mesh => {

            // get active geometry of mesh (and its' boundary box)
            const geometry = mesh.getActiveGeometry()
            const box = geometry.box()

            // add to boundary box of model
            modelBox.union(box)
        })

        // get model center
        const modelCenter = new THREE.Vector3()
        modelBox.getCenter(modelCenter)

        // get model size
        const modelSize = new THREE.Vector3()
        modelBox.getSize(modelSize)

        // calculate extent
        const extent = Math.max(modelSize.x, modelSize.y, modelSize.z)

        // calculate scale
        const scale = 2 / extent

        // adjust position of model
        this.setPosition(
            -modelCenter.x * scale, 
            -modelCenter.y * scale, 
            -modelCenter.z * scale)

        // adjust scale of model
        this.setScale(scale, scale, scale)
    }

    // model summary
    // returns a summary of this model
    summary(print=true) 
    {
        // begin message
        let msg = "\n[Model Summary]\n"

        // constant properties
        msg += "id: " + this.id + "\n"

        // convert vector to string
        const vec2str = (vector) => {
            return vector.x + "," + vector.y + "," + vector.z
        }

        // reactive properties
        msg += "name: " + this.reactive.name + "\n"
        msg += "version: " + this.reactive.version + "\n"
        msg += "ready: " + this.reactive.ready + "\n"
        msg += "position: " + vec2str(this.reactive.position) + "\n"
        msg += "rotation: " + vec2str(this.reactive.rotation) + "\n"
        msg += "scale: " + vec2str(this.reactive.scale) + "\n"

        // nonreactive properties
        msg += "mesh count: " + this.nonreactive.meshes.length + "\n"

        // iterate through all meshes
        this.nonreactive.meshes.forEach(mesh => {
            msg += mesh.summary(false)
        })
            
        // log to console
        if(print) console.log(msg)
    
        return msg
    }
}