import { reactive } from 'vue'

/*
 * Abstract Material Class
 */
export class AbstractMaterial 
{
    //// PROPERTIES

    // STATIC
    static lastId = 0   // remember last id 

    // CONSTANT
    // [id] unique identifier of material; incremented for each instantiated material instance
    // [mesh] reference to parent mesh (the material is part of) 
    // [locked] if true, material should not be changed (e.g., used for default materials)

    // REACTIVE
    // [name] name of the material (do not confuse with the name of the material type)
    // [version] internal counter to track current iteration of material (for external listeners)

    // NONREACTIVE
    // [transparency] define the level of transparency for this material (0: not tranparent, 1: fully transparent)
    // [state] stores all variables defining the state of this material (basically, its configuration)
    // [three] three.js material for which this class serves as a wrapper (state is applied to it)
    

    //// METHODS

    // class constructor
    constructor(
        mesh, 
        name, 
        locked=false, 
        opacity=1,
        update=true)
    {
        // constant properties
        this.id = ++AbstractMaterial.lastId
        this.mesh = mesh
        this.locked = locked

        // reactive properties
        this.reactive = reactive({
            name,
            version: 0
        })

        // nonreactive properties
        this.nonreactive = {
            opacity,
            state: {},
            three: null
        }

        // apply state
        if (update) this.update(false)
    }

    // update material
    // propagate: if true, the update propagates upward the hierarchy (i.e. update is triggered for mesh of material as well)
    update(propagate=true)
    {
        // update took place
        this.reactive.version += 1

        // propagate update to parent mesh
        if(propagate) this.mesh.update()
    }

    clone(mesh) {}

    // copy material
    // copy the state of the passed material to this material
    copy(material)
    {
        // flag material as updated
        this.update()
    }

    // material locked
    isLocked()
    {
        return this.locked
    }

    // material opacity
    // set opacity of material
    setOpacity(opacity)
    {
        this.nonreactive.state.opacity = opacity
    }

    // material three
    // returns the current three.js material
    three()
    {
        // return three.js material
        return this.nonreactive.three
    }

    // material summary
    // returns a summary of this material
    summary(print=true)
    {
        // begin message
        let msg = "\n[Material Summary]\n"

        // constant properties
        msg += "id: " + this.id + "\n"
        msg += "mesh: " + this.mesh.id + "\n"

        // reactive properties
        msg += "name: " + this.reactive.name + "\n"
        msg += "version: " + this.reactive.version + "\n"

        // log to console
        if(print) console.log(msg)

        return msg
    }
}