import * as THREE from 'three'


export function computeBoxVolume(width, height, depth) 
{
	// calculate volume in cubic millimeters
	const volumeInCubicMm = width * height * depth

	// convert cubic millimeters to liters (1,000,000 mm³ = 1 liter)
	const volumeInLiters = volumeInCubicMm / 1000000

	return volumeInLiters
}

export function computeMeshVolume(mesh, scale = [1, 1, 1]) 
{
    const geometry = mesh.geometry
    const positions = geometry.attributes.position.array
    const indices = geometry.index ? geometry.index.array : null
    let totalVolume = 0

    const scaleVector = new THREE.Vector3(scale[0], scale[1], scale[2])

    if (indices) 
    {
        for (let i = 0; i < indices.length; i += 3) {
            const idx0 = indices[i] * 3,
                idx1 = indices[i + 1] * 3,
                idx2 = indices[i + 2] * 3

            const p1 = new THREE.Vector3(
                positions[idx0],
                positions[idx0 + 1],
                positions[idx0 + 2]
            ).multiply(scaleVector)

            const p2 = new THREE.Vector3(
                positions[idx1],
                positions[idx1 + 1],
                positions[idx1 + 2]
            ).multiply(scaleVector)

            const p3 = new THREE.Vector3(
                positions[idx2],
                positions[idx2 + 1],
                positions[idx2 + 2]
            ).multiply(scaleVector)

            totalVolume += p1.dot(p2.cross(p3)) / 6
        }
    } 
    else 
    {
        for (let i = 0; i < positions.length; i += 9) 
        {
            const p1 = new THREE.Vector3(
                positions[i],
                positions[i + 1],
                positions[i + 2]
            ).multiply(scaleVector)

            const p2 = new THREE.Vector3(
                positions[i + 3],
                positions[i + 4],
                positions[i + 5]
            ).multiply(scaleVector)

            const p3 = new THREE.Vector3(
                positions[i + 6],
                positions[i + 7],
                positions[i + 8]
            ).multiply(scaleVector)

            totalVolume += p1.dot(p2.cross(p3)) / 6
        }
    }

    // convert volume to liters
    const volumeInLiters = Math.abs(totalVolume) / 1e3
    return volumeInLiters
}