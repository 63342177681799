import * as THREE from 'three'
import { AbstractMaterial } from '@/classes/data/material/AbstractMaterial'


/*
 * Texture Abstract Material Class
 */
export class TextureAbstractMaterial extends AbstractMaterial
{
    // class constructor
    constructor(
        mesh,
        name,
        texture,
        locked=false,
        opacity=1,
        update=true)
    {
        // call parent class constructor
        super(mesh, name, locked, opacity, false)

        // initialize state
        this.nonreactive.state = {
            texture,
            mapping: {
                //ref: null,
                positionU: 0,
                positionV: 0,
                rotation: 0,
                scaleU: 1,
                scaleV: 1
            },
            maps: {
                bumpActive: true,
                bumpStrength: 0.1,
                normalActive: true
            }
        }

        // apply state
        if (update) this.update(false)
    }

    // update material
    update(propagate=true)
    {
        // get references
        const state = this.nonreactive.state
        const three = this.nonreactive.three

        // material-independent three.js config
        three.side = THREE.DoubleSide
        three.transparent = true
        three.opacity = this.nonreactive.opacity

        // ensure texture and mapping exist
        if (state.texture == null || state.mapping == null) return

        const postProcessing = () => {

            // refresh the mapping
            this.updateTexture()

            // trigger update of parent class
            super.update(propagate)

            // flag as reloaded
            state.texture.reload = false
        }

        // detect if textures require loading
        if (state.texture.reload) //mapping.ref && state.mapping.ref == state.texture.id) 
        {
            const normalSuccess = (texture) => {

                // update bump texture
                three.normalMap = texture
                    
                postProcessing()
            }

            const bumpSuccess = (texture) => {

                // update bump texture
                three.bumpMap = texture

                // also load normal if exists
                /*if (state.texture.normalMap) 
                {
                    // load albedo texture 
                    this.loadTexture(state.texture.normalMap, normalSuccess)
                }
                else 
                {*/
                    three.normalMap = null
                    postProcessing()
                //}
            }
    
            const albedoSuccess = (texture) => {

                // update albedo texture
                three.map = texture
    
                // also load bump if exists
                if (state.texture.bumpMap) 
                {
                    // load albedo texture 
                    this.loadTexture(state.texture.bumpMap, bumpSuccess)
                }
                else 
                {
                    three.bumpMap = null
                    three.normalMap = null
                    postProcessing()
                }
            }
    
            // load albedo texture 
            this.loadTexture(state.texture.albedoMap, albedoSuccess)
        }
        
        // detect if the same texture is still selected
        else
            postProcessing()
    }

    updateTexture()
    {
        // get references
        const state = this.nonreactive.state
        const three = this.nonreactive.three

        // update material
        three.bumpScale = state.maps.bumpActive ? state.maps.bumpStrength : 0

        // get all textures
        const maps = [three.map, three.bumpMap] //, three.normalMap]

        // iterate through textures
        for (const texture of maps)
        {
            // ensure texture exists
            if (texture == null) return

            // configure texture wrapping
            //TODO utilize for tileable/outpainting
            texture.wrapS = THREE.RepeatWrapping
            texture.wrapT = THREE.RepeatWrapping
            
            // texture offset (position adjustment)
            texture.offset.set(state.mapping.positionU, state.mapping.positionV)

            // texture rotation
            texture.rotation = state.mapping.rotation * (Math.PI / 180)

            // texture scaling
            let scaleU = 2.0 - state.mapping.scaleU
            let scaleV = 2.0 - state.mapping.scaleV

            // apply power to values larger than one
            if (scaleU > 1) scaleU = Math.pow(scaleU, 4)
            if (scaleV > 1) scaleV = Math.pow(scaleV, 4)

            // avoid zero
            if (scaleU == 0) scaleU = 0.01
            if (scaleV == 0) scaleV = 0.01
                
            // apply scaling
            texture.repeat.set(scaleU, scaleV)

            // flag as updated
            texture.needsUpdate = true 
        }
    }

    loadTexture(texture, callback)
    {
        // image object
        const image = new Image()

        // specify loading function of image
        image.onload = () => {

            // get three.js texture from image
            const texture = new THREE.Texture(image)

            if (callback && typeof callback === 'function')
                callback(texture)
        }

        // set texture as image source
        image.src = texture
    }
}