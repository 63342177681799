import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { base64ToBinary, base64ToArrayBuffer } from '@/utils/file.js'
import { mergeGeometries } from '@/utils/geometry.js'


export async function loadMesh(extension, base64) 
{
	const material = new THREE.MeshStandardMaterial({ color: 0xffffff })
	let mesh;

	switch (extension) 
	{
		case 'glb':
		case 'gltf':
			{
				const loader = new GLTFLoader()
				const gltfArrayBuffer = base64ToArrayBuffer(base64);

				// parse the GLTF data asynchronously
				mesh = await new Promise((resolve, reject) => {
					loader.parse(
						gltfArrayBuffer,
						'',
						(gltf) => {
							// merge all geometries into one mesh
							const geometry = mergeGeometries(gltf.scene)
							geometry.computeVertexNormals()
							const mesh = new THREE.Mesh(geometry, material)
							resolve(mesh)
						},
						(error) => {
							console.error('Error loading glTF model:', error)
							reject(error)
						}
					)
				})
			}
			break

		case 'obj':
			{
				const loader = new OBJLoader()
				const objText = base64ToBinary(base64)
				const object3D = loader.parse(objText)
				const geometry = mergeGeometries(object3D)
				mesh = new THREE.Mesh(geometry, material)
			}
			break

		case 'stl':
			{
				const loader = new STLLoader()
				const stlArrayBuffer = base64ToArrayBuffer(base64)
				const geometry = loader.parse(stlArrayBuffer)
				mesh = new THREE.Mesh(geometry, material)
			}
			break

		default:
			console.error('Unsupported file type:', extension)
			return null
	}

	return mesh
}