// vuex.js state for Texturizer
import { extractExtension } from '@/utils/file.js'


// windows
const WINDOW_IMAGE = 'image'
const WINDOW_DEPTH = 'depth'
const WINDOW_SEGMENT = 'segment'
const WINDOW_COMPOSE = 'compose'
const WINDOW_EXPORT = 'export'
const WINDOW_DEFAULT = WINDOW_IMAGE


// modes
const VIEWER_MODE_COLOR = 'color'
const VIEWER_MODE_DEPTH = 'depth'
const VIEWER_MODE_DEFAULT = VIEWER_MODE_COLOR


export const texturizer = {
    namespaced: true,
    state: {
        window: WINDOW_DEFAULT,
        image: {
            asset: null,
            depth: 1
        },
        viewer: {
            mode: VIEWER_MODE_DEFAULT,
            cleanup: false
        }
    },
    mutations: {
        SET_WINDOW(state, window) {
            state.window = window
        },
        SET_IMAGE_ASSET(state, asset) {
            state.image.asset = asset
        },
        SET_IMAGE_DEPTH(state, depth) {
            state.image.depth = depth
        },
        SET_VIEWER_MODE(state, mode) {
            state.viewer.mode = mode
        },
        SET_VIEWER_CLEANUP(state, cleanup) {
            state.viewer.cleanup = cleanup
        },
        TOGGLE_VIEWER_CLEANUP(state) {
            state.viewer.cleanup = !state.viewer.cleanup
        }
    },
    getters: {
        keyWindowDefault: () => WINDOW_DEFAULT,
        keyWindowImage: () => WINDOW_IMAGE,
        keyWindowDepth: () => WINDOW_DEPTH,
        keyWindowSegment: () => WINDOW_SEGMENT,
        keyWindowCompose: () => WINDOW_COMPOSE,
        keyWindowExport: () => WINDOW_EXPORT,
        hasImageFileDefault: (state, getters) => getters.getImageFileDefault !== null,
        hasImageFileDepth: (state, getters) => getters.getImageFileDepth !== null,
        hasImageFileSegment: (state, getters) => getters.getImageFileSegment !== null,
        isWindowDefault: state => state.window === WINDOW_DEFAULT,
        isWindowImage: state => state.window === WINDOW_IMAGE,
        isWindowDepth: state => state.window === WINDOW_DEPTH,
        isWindowSegment: state => state.window === WINDOW_SEGMENT,
        isWindowCompose: state => state.window === WINDOW_COMPOSE,
        isWindowExport: state => state.window === WINDOW_EXPORT,
        isWindowImageDisabled: () => false,
        isWindowDepthDisabled: (state, getters) => !getters.hasImageFileDepth,
        isWindowSegmentDisabled: (state, getters) => !getters.hasImageFileSegment,
        isWindowComposeDisabled: (state, getters) => !getters.hasImageFileDepth || !getters.hasImageFileSegment,
        isWindowExportDisabled: () => true,
        isViewerModeDefault: state => state.viewer.mode === VIEWER_MODE_DEFAULT,
        isViewerModeColor: state => state.viewer.mode === VIEWER_MODE_COLOR,
        isViewerModeDepth: state => state.viewer.mode === VIEWER_MODE_DEPTH,
        isViewerCleanup: state => state.viewer.cleanup,
        getWindow: state => state.window,
        getWindowOrder: () => (window) => {
            if (window === WINDOW_IMAGE) return 1
            if (window === WINDOW_DEPTH) return 2
            if (window === WINDOW_SEGMENT) return 3
            if (window === WINDOW_COMPOSE) return 4
            if (window === WINDOW_EXPORT) return 5
            return 0
        },
        getImageAsset: state => state.image.asset,
        getImageFile: state => (fileKey) => {
            const asset = state.image.asset
            if (asset?.files[fileKey]) return asset.files[fileKey]
            return null
        },
        getImageFileDefault: (state, getters) => getters.getImageFile('default'),
        getImageFileDepth: (state, getters) => getters.getImageFile('depth'),
        getImageFileSegment: (state, getters) => getters.getImageFile('segment'),
        getImageDepth: state => state.image.depth,
        getImageRecents: (state, getters, rootState, rootGetters) => {
            let assets = rootGetters['api/getFilteredAssets'](
                rootGetters['app/keyAppTexturizer'],
                rootGetters['api/keyAssetTypeImage']
            )
            if (assets) 
            {
                assets = assets
                .sort((a, b) => b.created - a.created)
                return assets.slice(0, 3)
            } 
            return null
        },
        getImageExamples: (state, getters, rootState, rootGetters) => {
            let assets = rootGetters['api/getFilteredAssets'](
                rootGetters['app/keyAppSketchurizer'],
                rootGetters['api/keyAssetTypeLayer']
            )
            if (assets) {
                assets = assets.sort((a, b) => b.created - a.created)
                return assets.slice(0, 3)
            }
            return null
        },
        getViewerMode: state => state.viewer.mode
    },
    actions: {
        setWindow({ commit }, window) {
            commit('SET_WINDOW', window)
        },
        setWindowToDefault({ commit }) {
            commit('SET_WINDOW', WINDOW_DEFAULT)
        },
        setWindowToImage({ commit }) {
            commit('SET_WINDOW', WINDOW_IMAGE)
        },
        setWindowToDepth({ commit }) {
            commit('SET_WINDOW', WINDOW_DEPTH)
        },
        setWindowToSegment({ commit }) {
            commit('SET_WINDOW', WINDOW_SEGMENT)
        },
        setWindowToCompose({ commit }) {
            commit('SET_WINDOW', WINDOW_COMPOSE)
        },
        setWindowToExport({ commit }) {
            commit('SET_WINDOW', WINDOW_EXPORT)
        },
        clearImageAsset({ commit }) {
            commit('SET_IMAGE_ASSET', null)
        },
        async importImage({ dispatch, rootGetters }, { file, base64 }) 
        {
            // ensure file parameter is provided
            if (file === null || file === undefined) {
                throw new Error("Please provide a file to import.")
            }

            // ensure base64 parameter is provided
            if (base64 === null || base64 === undefined) {
                throw new Error("Please provide the file content to import.")
            }      
            
            try
            {
                //// PREPROCESS

                // extract extension from file name
                let extension = extractExtension(file.name)

                // supported formats
                const supported = ['png', 'jpg']

                // if format is not supported
                if (!supported.includes(extension)) {
                    throw new Error("The file format is not supported.")
                }

                // change generic mime type according to extension
                base64 = base64.replace('application/octet-stream', 'image/' + extension)

                // promise to check if image is square sized
                const isSquare = await new Promise((resolve, reject) => {
                    const img = new Image()
                    img.onload = () => resolve(img.naturalWidth === img.naturalHeight)
                    img.onerror = () => reject(new Error("The image could not be imported."))
                    img.src = base64
                })
                if (!isSquare) {
                    throw new Error("Currently, we only support square-sized images.")
                }

                // call route to upload the image to an asset
                const asset = await dispatch('api/imagesFromFileConvertPost', {
                    file: base64
                }, { root: true })

                // update image asset
                const statusRetrieved = rootGetters['api/keyAssetStatusRetrieved']
                asset.files['default'].data = base64
                asset.files['default'].status = statusRetrieved

                // set image asset
                dispatch('setImageAsset', asset)

                return asset
            }
            catch(error)
            {
                throw error
            }
        },
        async setImageAsset({ commit, dispatch, rootGetters }, asset) 
        {
            commit('SET_IMAGE_ASSET', asset)

            try
            {
                // if asset is empty: nothing to do
                if (asset === null) return null

                // if asset is of type layer
                if (rootGetters['api/isAssetTypeLayer'](asset))
                {
                    // call route to convert layer to image
                    asset = await dispatch('api/imagesFromLayerGeneratePost', {
                        parentId: asset.id,
                        background: [255, 255, 255],
                        additional: asset.additional
                    }, { root: true })

                    // update image asset
                    commit('SET_IMAGE_ASSET', asset)
                }

                // if asset file default is unrequested: retrieve it
                const statusUnrequested = rootGetters['api/keyAssetStatusUnrequested']
                if (asset.files['default'].status === statusUnrequested) 
                {
                    // retrieve asset
                    await dispatch('retrieveImageFile', 'default')
                }

                // generate depth image
                if (!('depth' in asset.files)) await dispatch('generateImageFileDepth')
                dispatch('retrieveImageFile', 'depth')

                // generate segment image
                if (!('segment' in asset.files)) await dispatch('generateImageFileSegment')
                dispatch('retrieveImageFile', 'segment')

                return
            }
            catch(error)
            {
                console.log(error)
                throw error
            }
        },
        async retrieveImageFile({ getters, dispatch, rootGetters }, fileKey) 
        {
            try 
            {
                // get image asset
                const asset = getters.getImageAsset

                // ensure image asset exists
                if (asset === null || asset === undefined) {
                    throw new Error("Please provide an image asset.")
                }

                // call route to retrieve depth file
                const response = await dispatch('api/assetsAssetFileKeyGet', {
                    asset,
                    key: fileKey
                }, { root: true })

                return response
            }
            catch(error)
            {
                throw error
            }
        },
        async generateImageFileDepth({ getters, dispatch, rootGetters })
        {
            try
            {
                // get image asset
                const asset = getters.getImageAsset

                // ensure image asset exists
                if (asset === null || asset === undefined) {
                    throw new Error("Please provide an image asset.")
                }

                // call route to generate a depth image
                const response = await dispatch('api/imagesImageDepthGeneratePost', {
                    asset
                }, { root: true })

                return response
            }
            catch(error)
            {
                throw error
            }
        },
        async generateImageFileSegment({ getters, dispatch })
        {
            try
            {
                // get image asset
                const asset = getters.getImageAsset

                // ensure image asset exists
                if (asset === null || asset === undefined) {
                    throw new Error("Please provide an image asset.")
                }

                // call route to generate a depth image
                const response = await dispatch('api/imagesImageSegmentGeneratePost', {
                    asset
                }, { root: true })

                return response
            }
            catch(error)
            {
                throw error
            }
        },
        setImageDepth({ commit }, depth) {
            commit('SET_IMAGE_DEPTH', depth)
        },
        setViewerMode({ commit }, mode) {
            commit('SET_VIEWER_MODE', mode)
        },
        setViewerModeToDefault({ commit }) {
            commit('SET_VIEWER_MODE', VIEWER_MODE_DEFAULT)
        },
        setViewerModeToColor({ commit }) {
            commit('SET_VIEWER_MODE', VIEWER_MODE_COLOR)
        },
        setViewerModeToDepth({ commit }) {
            commit('SET_VIEWER_MODE', VIEWER_MODE_DEPTH)
        },
        toggleViewerCleanup({ commit }) {
            commit('TOGGLE_VIEWER_CLEANUP')
        }
    }
}