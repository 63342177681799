//// IMAGE

// resize image
export const resizeImage = (() => 
{
    // create a single canvas element and store it in the closure
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    return function(base64Image, width, height) {
        return new Promise((resolve, reject) => {
            const img = new Image()
            img.onload = () => {

                // set the canvas dimensions
                canvas.width = width || img.width
                canvas.height = height || img.height
                ctx.clearRect(0, 0, canvas.width, canvas.height) // Clear the canvas before drawing

                // draw the image on the canvas
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

                // extract the file type from the base64 string
                const fileType = base64Image.substring(base64Image.indexOf(':') + 1, base64Image.indexOf(';'))
                const dataUrl = canvas.toDataURL(fileType)
                
                resolve(dataUrl)
            }
            img.onerror = error => reject(error)
            img.src = base64Image
        })
    }
})()