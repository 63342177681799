// Composables
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/plugins/vuex'

const routes = [
	{
		path: '/',
		component: () => import('@/layout/AppBarLayout.vue'),
		children: [
			{
				path: '',
				name: 'AppsView',
				component: () => import('@/views/AppsView.vue'),
				meta: {
					logo: null,
					appsButton: false,
					requiresAuth: true,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/login',
		component: () => import('@/layout/AppBarLayout.vue'),
		children: [
			{
				path: '',
				name: 'LoginView',
				component: () => import('@/views/LoginView.vue'),
				meta: {
					logo: null,
					appsButton: false,
					requiresAuth: false,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/account',
		component: () => import('@/layout/AppBarLayout.vue'),
		children: [
			{
				path: '',
				name: 'AccountView',
				component: () => import('@/views/AccountView.vue'),
				meta: {
					title: 'Account',
					logo: null,
					appsButton: true,
					requiresAuth: true,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/admin',
		component: () => import('@/layout/AppBarLayout.vue'),
		children: [
			{
				path: '',
				name: 'AdminView',
				component: () => import('@/views/AdminView.vue'),
				meta: {
					title: 'Admin',
					logo: null,
					appsButton: true,
					requiresAuth: true,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/share',
		component: () => import('@/layout/BaseLayout.vue'), //TODO appbar layout
		children: [
			{
				path: '',
				name: 'ShareView',
				component: () => import('@/views/ShareView.vue'),
				meta: {
					logo: null,
					appsButton: true,
					requiresAuth: false,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/sketch',
		component: () => import('@/layout/BaseLayout.vue'),
		children: [
			{
				path: '',
				name: 'SketchView',
				component: () => import('@/views/SketchView.vue'),
				meta: {
					title: 'Sketchurizer',
					subtitle: 'Preview',
					logo: '/logo/sketchurizer.png',
					appsButton: true,
					requiresAuth: true,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/model',
		component: () => import('@/layout/BaseLayout.vue'),
		children: [
			{
				path: '',
				name: 'ModelView',
				component: () => import('@/views/ModelView.vue'),
				meta: {
					title: 'Modulator',
					subtitle: 'Preview',
					logo: '/logo/modulator.png',
					appsButton: true,
					requiresAuth: true,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/text',
		component: () => import('@/layout/BaseLayout.vue'),
		children: [
			{
				path: '',
				name: 'TextView',
				component: () => import('@/views/TextView.vue'),
				meta: {
					title: 'Texturizer',
					subtitle: 'Preview',
					logo: '/logo/prototype.png',
					appsButton: true,
					requiresAuth: true,
					requiresDev: false
				}
			}
		]
	},
	{
		path: '/box',
		component: () => import('@/layout/BaseLayout.vue'),
		children: [
			{
				path: '',
				name: 'BoxView',
				component: () => import('@/views/BoxView.vue'),
				meta: {
					title: 'Boxurizer',
					subtitle: 'Preview',
					logo: '/logo/prototype.png',
					appsButton: true,
					requiresAuth: true,
					requiresDev: false
				}
			}
		]
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	
	// set title of page
    document.title = to.meta.title ? 'GenerIO | ' + to.meta.title : 'GenerIO'

	// set loading state
	store.dispatch('app/activateLoading')

	// handle restrictions
    const isAuthenticated = store.getters['app/isAuthenticated']
	const isDeveloper = store.getters['app/isDeveloper']
	const isSketchurizerOnly = store.getters['app/isSketchurizerOnly']
	const isTexturizer = store.getters['app/isTexturizer']
	const isBoxurizer  = store.getters['app/isBoxurizer']

	// developer restrictions
	if (to.meta.requiresDev && !isDeveloper) 
	{
		if (isAuthenticated) next({ name: 'AppsView' })
		else next({ name: 'LoginView' })
	}
	// authentication restrictions
	else if (to.meta.requiresAuth && !isAuthenticated)
	{
		next({ name: 'LoginView' })	
	}
	// app restrictions
	else if ((to.path === '/text' && !isTexturizer) ||
			 (to.path === '/box' && !isBoxurizer))
	{
		next({ name: 'AppsView' })	
	}
	// apps (app list) restrictions
	else if (to.path === '/' && isSketchurizerOnly)
	{
		next({ name: 'SketchView' })	
	}
	else  
	{
		next()
	}
})

export default router