// vuex.js store for Sketchurizer


//// IMPORTS
import { resizeImage } from '@/utils/image' 


// WINDOW CONSTANTS
const WINDOW_LIBRARY = 'library'
const WINDOW_INPUT = 'input'
const WINDOW_PREVIEW = 'preview'
const WINDOW_MODEL = 'model'
const WINDOW_DEFAULT = WINDOW_INPUT


// WIDGET CONSTANTS
const WIDGET_SKETCH = 'sketch'
const WIDGET_UPLOAD = 'upload'
const WIDGET_CAMERA = 'camera'
const WIDGET_DEFAULT = WIDGET_SKETCH


// INPUT CONSTANTS
const INPUT_STRENGTH_DEFAULT = 0.5


// PROMPT CONSTANTS
const PROMPT_POSITIVE_DEFAULT = 'plain background, long shot, single object' 
const PROMPT_NEGATIVE_DEFAULT = ''


// GENERATION CONSTANTS
const GENERATION_METHOD_FAST = 'faster generation'
const GENERATION_METHOD_HIGH = 'higher quality' 
const GENERATION_METHOD_DEFAULT = GENERATION_METHOD_HIGH


// VIEWER CONSTANTS
const VIEWER_MODE_TEXTURE = 'texture'
const VIEWER_MODE_SOLID = 'solid'
const VIEWER_MODE_WIREFRAME = 'wireframe'
const VIEWER_MODE_DEFAULT = VIEWER_MODE_TEXTURE

const VIEWER_FILE_STANDARD = 'standard'
const VIEWER_FILE_OPTIMIZED = 'optimized'


// LIBRARY CONSTANTS
const LIBRARY_MODE_FLOW = 'flows'
const LIBRARY_MODE_INPUT = 'input'
const LIBRARY_MODE_PREVIEW = 'previews'
const LIBRARY_MODE_MODEL = 'model'
const LIBRARY_MODE_DEFAULT = LIBRARY_MODE_FLOW


// SORTING CONSTANTS
const SORT_TYPE_DATE = 'date'
const SORT_TYPE_RATING = 'rating'
const SORT_TYPE_DEFAULT = SORT_TYPE_DATE

const SORT_ORDER_ASCENDING = 'ascending'
const SORT_ORDER_DESCENDING = 'descending'
const SORT_ORDER_DEFAULT = SORT_ORDER_DESCENDING


//// VUEX STORE
export const sketchurizer = {
    namespaced: true,
    state: {
        activeInput: {
            raw: null,          // sketch output
            strokes: 0,  
            background: null,   // sketch input (e.g., upload output)
            upload: null,       // upload input (TODO rename to image)
            asset: null,
            assetIgnore: false,
            assetOutdated: false
        },
        activePreviewAssets: null,
        activeModelAsset: null,

        activeWindow: WINDOW_DEFAULT,
        activeWidget: WIDGET_DEFAULT,

        library: {
            mode: LIBRARY_MODE_DEFAULT,
            searchQuery: '',
            sortingType: SORT_TYPE_DEFAULT,
            sortingOrder: SORT_ORDER_DEFAULT
        },

        inputStrength: INPUT_STRENGTH_DEFAULT,
        promptPositive: '',
        promptNegative: '',

        viewer: {
            mode: VIEWER_MODE_DEFAULT,
            file: VIEWER_FILE_STANDARD
        }
    },
    mutations: {
        RESET(state) {
            state.activeInput.raw = null
            state.activeInput.strokes = 0
            state.activeInput.background = null
            state.activeInput.upload = null
            state.activeInput.asset = null
            state.activeInput.assetIgnore = false
            state.activeInput.assetOutdated = false
            state.activePreviewAssets = null
            state.activeModelAsset = null

            state.inputStrength = INPUT_STRENGTH_DEFAULT
            state.promptPositive = ''
            state.promptNegative = ''

            state.viewer.mode = VIEWER_MODE_DEFAULT
            state.viewer.file = VIEWER_FILE_STANDARD
        },

        SET_ACTIVE_INPUT_RAW(state, raw) {
            state.activeInput.raw = raw

            if (state.activeInput.assetIgnore) {
                state.activeInput.assetIgnore = false
            } else {
                state.activeInput.assetOutdated = true
            }
        },
        SET_ACTIVE_INPUT_STROKES(state, strokes) {
            state.activeInput.strokes = strokes
        },
        SET_ACTIVE_INPUT_BACKGROUND(state, background) {
            state.activeInput.background = background
        },
        SET_ACTIVE_INPUT_UPLOAD(state, upload) {
            state.activeInput.upload = upload
        },
        SET_ACTIVE_INPUT_ASSET(state, asset) {
            const data = asset.files['default'].data 
            state.activeInput.background = data
            state.activeInput.asset = asset
            state.activeInput.assetIgnore = true
            state.activeInput.assetOutdated = false
        },
        SET_ACTIVE_PREVIEW_ASSETS(state, assets) {
            state.activePreviewAssets = assets
        },
        SET_ACTIVE_MODEL_ASSET(state, asset) {
            state.activeModelAsset = asset
        },

        SET_ACTIVE_WINDOW(state, window) {
            state.activeWindow = window
        },
        SET_ACTIVE_WIDGET(state, widget) {
            state.activeWidget = widget
        },

        SET_LIBRARY_MODE(state, mode) {
            state.library.mode = mode
        },
        SET_LIBRARY_SEARCHQUERY(state, searchQuery) {
            state.library.searchQuery = searchQuery
        },
        SET_LIBRARY_SORTINGTYPE(state, sortingType) {
            state.library.sortingType = sortingType
        },
        SET_LIBRARY_SORTINGORDER(state, sortingOrder) {
            state.library.sortingOrder = sortingOrder
        },

        SET_INPUT_STRENGTH(state, strength) {
            state.inputStrength = strength
        },
        SET_PROMPT_POSITIVE(state, prompt) {
            state.promptPositive = prompt
        },
        SET_PROMPT_NEGATIVE(state, prompt) {
            state.promptNegative = prompt
        },

        SET_VIEWER_MODE(state, mode) {
            state.viewer.mode = mode
        },
        SET_VIEWER_FILE(state, file) {
            state.viewer.file = file
        }
    },
    getters: {
        keyWindowDefault: () => WINDOW_DEFAULT,
        keyWindowLibrary: () => WINDOW_LIBRARY,
        keyWindowInput: () => WINDOW_INPUT,
        keyWindowPreview: () => WINDOW_PREVIEW,
        keyWindowModel: () => WINDOW_MODEL,

        keyWidgetSketch: () => WIDGET_SKETCH,
        keyWidgetUpload: () => WIDGET_UPLOAD,
        keyWidgetCamera: () => WIDGET_CAMERA,

        keyLibraryModeDefault: () => LIBRARY_MODE_DEFAULT,
        keyLibraryModeFlow: () => LIBRARY_MODE_FLOW,
        keyLibraryModeInput: () => LIBRARY_MODE_INPUT,
        keyLibraryModePreview: () => LIBRARY_MODE_PREVIEW,
        keyLibraryModeModel: () => LIBRARY_MODE_MODEL,

        keySortTypeDate: () => SORT_TYPE_DATE,
        keySortTypeRating: () => SORT_TYPE_RATING,

        keySortOrderAscending: () => SORT_ORDER_ASCENDING,
        keySortOrderDescending: () => SORT_ORDER_DESCENDING,

        keyInputStrengthDefault: () => INPUT_STRENGTH_DEFAULT,

        keyPromptPositiveDefault: () => PROMPT_POSITIVE_DEFAULT,
        keyPromptNegativeDefault: () => PROMPT_NEGATIVE_DEFAULT,

        keyGenerationMethodDefault: () => GENERATION_METHOD_DEFAULT,
        keyGenerationMethodFast: () => GENERATION_METHOD_FAST,
        keyGenerationMethodHigh: () => GENERATION_METHOD_HIGH,

        keyViewerModeDefault: () => VIEWER_MODE_DEFAULT,
        keyViewerModeTexture: () => VIEWER_MODE_TEXTURE,
        keyViewerModeSolid: () => VIEWER_MODE_SOLID,
        keyViewerModeWireframe: () => VIEWER_MODE_WIREFRAME,

        keyViewerFileStandard: () => VIEWER_FILE_STANDARD,
        keyViewerFileOptimized: () => VIEWER_FILE_OPTIMIZED,

        getActiveInputRaw: (state) => state.activeInput.raw,
        getActiveInputStrokes: (state) => state.activeInput.strokes,
        getActiveInputBackground: (state) => state.activeInput.background,
        getActiveInputUpload: (state) => state.activeInput.upload,
        getActiveInputAsset: (state) => state.activeInput.asset,
        getActivePreviewAssets: (state) => state.activePreviewAssets,
        getActiveModelAsset: (state) => state.activeModelAsset,

        getAllAssets: (state, getters, rootState, rootGetters) => {
            return rootGetters['api/getFilteredAssets'](
                rootGetters['app/keyAppSketchurizer']
            )
        },
        getAllBlueprints: (state, getters, rootState, rootGetters) => {
            let assets = getters.getAllAssets
            assets = assets.filter(asset => 
                asset.type === rootGetters['api/keyAssetTypeImage'] &&
                asset.generated === true && 
                asset.additional?.blueprint
            )
            assets.sort((a, b) => b.created - a.created)
            return assets
        },
        getFilteredAssets: (state, getters) => {
            // get array of assets
            let assets = [...getters.getAllAssets]
            if (!assets) return []

            // apply search query
            const searchQuery = getters.getLibrarySearchQuery
            const optimizedQuery = searchQuery ? searchQuery.trim().toLowerCase() : null
            if (optimizedQuery) {
                assets = assets.filter(asset => 
                    asset.additional?.positive?.toLowerCase().includes(optimizedQuery)
                )
            }

            // get sorting
            const sortingType = getters.getLibrarySortingType
            const sortingOrder = getters.getLibrarySortingOrder

            // sort by date
            if (sortingType === SORT_TYPE_DATE) 
            {
                if (sortingOrder === SORT_ORDER_DESCENDING) {
                    assets.sort((a, b) => b.created - a.created)
                } else {
                    assets.sort((a, b) => a.created - b.created)
                }
            } 

            // sort by type
            else if (sortingType === SORT_TYPE_RATING) 
            {
                if (sortingOrder === SORT_ORDER_DESCENDING) {
                    assets.sort((a, b) => 
                        (b.additional?.rating || 0) - (a.additional?.rating || 0)
                    )
                } else {
                    assets.sort((a, b) => 
                        (a.additional?.rating || 0) - (b.additional?.rating || 0)
                    )
                }
            }

            return assets
        },
        getFilteredInputAssets: (state, getters, rootState, rootGetters) => {
            return getters.getFilteredAssets.filter(asset =>
                asset.type === rootGetters['api/keyAssetTypeImage']
            )
        },
        getFilteredPreviewAssets: (state, getters, rootState, rootGetters) => {
            return getters.getFilteredAssets.filter(asset =>
                asset.type === rootGetters['api/keyAssetTypeLayer']
            )
        },
        getFilteredModelAssets: (state, getters, rootState, rootGetters) => {
            return getters.getFilteredAssets.filter(asset =>
                asset.type === rootGetters['api/keyAssetTypeModel']
            )
        },
        getAssetCategory: (state, getters, rootState, rootGetters) => (asset) => {
            if (rootGetters['api/isAssetTypeImage'](asset)) return 'input'
            if (rootGetters['api/isAssetTypeLayer'](asset)) return 'preview'
            return asset.type
        },

        getActiveWindow: state => state.activeWindow,
        getActiveWidget: state => state.activeWidget,

        getLibraryMode: state => state.library.mode,
        getLibrarySearchQuery: state => state.library.searchQuery,
        getLibrarySortingType: state => state.library.sortingType,
        getLibrarySortingOrder: state => state.library.sortingOrder,

        getInputStrength: state => state.inputStrength,
        getPromptPositive: state => state.promptPositive,
        getPromptNegative: state => state.promptNegative,

        getViewerMode: state => state.viewer.mode,
        getViewerFile: (state, getters) => {
            if (state.viewer.file === VIEWER_FILE_OPTIMIZED)
            {
                if (getters.hasActiveModelOptimized) 
                    return VIEWER_FILE_OPTIMIZED
            }
            return VIEWER_FILE_STANDARD
        },

        getAdditionalCustomPositive: (state, getters, rootState, rootGetters) => {
            const additional = rootGetters['app/getAdditional']
            const prompt = additional?.sketchCustomPositive
            if (prompt) return prompt
            return ''
        },
        getAdditionalCustomNegative: (state, getters, rootState, rootGetters) => {
            const additional = rootGetters['app/getAdditional']
            const prompt = additional?.sketchCustomNegative
            if (prompt) return prompt
            return ''
        },
        getAdditionalGenerationMethod: (state, getters, rootState, rootGetters) => {
            const additional = rootGetters['app/getAdditional']
            const method = additional?.sketchGenerationMethod
            if (method) return method
            return GENERATION_METHOD_DEFAULT
        },
        getAdditionalExportFormat: (state, getters, rootState, rootGetters) => {
            const additional = rootGetters['app/getAdditional']
            const format = additional?.sketchExportFormat
            if (format) return format
            return rootGetters['api/keyModelExportFormatDefault']
        },

        hasActiveInputAsset: state => state.activeInput.asset !== null,
        hasActivePreviewAssets: state => state.activePreviewAssets !== null,
        hasActiveModelAsset: state => state.activeModelAsset !== null,
        hasActiveModelOptimized: state => {
            if (state.activeModelAsset && 'optimize' in state.activeModelAsset.files) return true
            return false
        },
        hasPromptPositive: state => state.promptPositive !== null && state.promptPositive !== undefined && state.promptPositive !== '',
        hasPromptNegative: state => state.promptNegative !== null && state.promptNegative !== undefined && state.promptNegative !== '',
        hasAdditionalCustom: (state, getters, rootState, rootGetters) => {
            // get additional
            const additional = rootGetters['app/getAdditional']
            const positive = additional?.sketchCustomPositive
            if (positive && positive.length > 0) return true
            const negative = additional?.sketchCustomNegative
            if (negative && negative.length > 0) return true
            return false
        },

        isActiveInputEmpty: state => {
            if (state.activeInput.raw === null) return true
            if (state.activeInput.background !== null) return false
            if (state.activeInput.strokes !== null && state.activeInput.strokes > 0) return false
            return true
        },
        isActiveInputAssetOutdated: state => state.activeInput.assetOutdated,

        isWindowDefault: state => state.activeWindow === WINDOW_DEFAULT,
        isWindowLibrary: state => state.activeWindow === WINDOW_LIBRARY,
        isWindowInput: state => state.activeWindow === WINDOW_INPUT,
        isWindowPreview: state => state.activeWindow === WINDOW_PREVIEW,
        isWindowModel: state => state.activeWindow === WINDOW_MODEL,

        isWidgetDefault: state => state.activeWidget === WIDGET_DEFAULT,
        isWidgetSketch: state => state.activeWidget === WIDGET_SKETCH,
        isWidgetUpload: state => state.activeWidget === WIDGET_UPLOAD,
        isWidgetCamera: state => state.activeWidget === WIDGET_CAMERA,

        isViewerModeDefault: state => state.viewer.mode === VIEWER_MODE_DEFAULT,
        isViewerModeTexture: state => state.viewer.mode === VIEWER_MODE_TEXTURE,
        isViewerModeSolid: state => state.viewer.mode === VIEWER_MODE_SOLID,
        isViewerModeWireframe: state => state.viewer.mode === VIEWER_MODE_WIREFRAME,

        isAdditionalTutorial: (state, getters, rootState, rootGetters) => {
            // get additional
            const additional = rootGetters['app/getAdditional']
            
            // get tutorial 
            const tutorial = additional?.sketchTutorial

            // if tutorial exists
            if (tutorial) return tutorial

            return false
        },
        isAdditionalGenerationPersistent: (state, getters, rootState, rootGetters) => {
            // get additional
            const additional = rootGetters['app/getAdditional']
            
            // get flag persistent
            const persistent = additional?.sketchGenerationPersistent

            // if flag exists
            if (persistent) return persistent

            return false
        },
    },
    actions: {

        reset({ commit }) {
            commit('RESET')
        },

        // open an asset (input, preview, or model)
        async openAsset({ dispatch, rootGetters }, {
            asset,
            show = true
        }) 
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to open.")
            }

            try
            {
                // reset vuex store 
                await dispatch('reset')

                // if input asset
                if (rootGetters['api/isAssetTypeImage'](asset))
                {
                    return dispatch('openAssetAsInput', { asset, show })
                }

                // if preview asset
                else if (rootGetters['api/isAssetTypeLayer'](asset))
                {
                    return dispatch('openAssetAsPreview', { asset, show })
                }

                // if model asset
                else if (rootGetters['api/isAssetTypeModel'](asset))
                {
                    return dispatch('openAssetAsModel', { asset, show })
                }

                else 
                {
                    throw new Error('Cannot open unsupported asset type.')
                }
            }
            catch(error)
            {
                throw error
            }
        },

        // open asset as input
        async openAssetAsInput({ dispatch, rootGetters }, {
            asset,
            show = true
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to open.")
            }

            // ensure asset type is supported for input
            if (!rootGetters['api/isAssetTypeImage'](asset) && 
                !rootGetters['api/isAssetTypeLayer'](asset)) {
                throw new Error("Cannot open unsupported asset type.")
            }

            try
            {
                // call route to retrieve asset file (with default key 'default')
                const response = await dispatch('api/assetsAssetFileKeyGet', { 
                    asset 
                }, { root: true })

                // after the file has been retreived, set the asset as active input
                dispatch('setActiveInputAsset', asset)

                // set prompt 
                if (asset.additional && asset.additional.positive)
                    dispatch('setPromptPositive', asset.additional.positive)
                if (asset.additional && asset.additional.negative)
                    dispatch('setPromptNegative', asset.additional.negative)

                // set guidance strength
                if (asset.additional && asset.additional.guidance)
                    dispatch('setInputStrength', asset.additional.guidance)

                // set window and widget to sketch input
                if (show) {
                    dispatch('setActiveWindowToInput')
                    dispatch('setActiveWidgetToSketch')
                }

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // open asset as preview
        async openAssetAsPreview({ dispatch, rootGetters }, {
            asset,
            show = true
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to open.")
            }

            // ensure asset type is supported for input
            if (!rootGetters['api/isAssetTypeImage'](asset) && 
                !rootGetters['api/isAssetTypeLayer'](asset)) {
                throw new Error("Cannot open unsupported asset type.")
            }

            try
            {
                // call route to retrieve asset file (with default key 'default')
                const response = await dispatch('api/assetsAssetFileKeyGet', { 
                    asset
                }, { root: true })

                // after the file has been retreived, set the asset as active preview
                dispatch('setActivePreviewAssets', [ asset ])

                // set prompt 
                if (asset.additional && asset.additional.positive)
                    dispatch('setPromptPositive', asset.additional.positive)
                if (asset.additional && asset.additional.negative)
                    dispatch('setPromptNegative', asset.additional.negative)

                // set guidance strength
                if (asset.additional && asset.additional.guidance)
                    dispatch('setInputStrength', asset.additional.guidance)

                // has parent?
                if (asset.parent) 
                {
                    // get parent asset (input)
                    const parentAsset = rootGetters['api/getAsset'](asset.parent)

                    // parent retrieved successfully?
                    if (parentAsset) await dispatch('openAssetAsInput', { asset: parentAsset, show })
                }

                // set window to preview
                if (show) {
                    dispatch('setActiveWindowToPreview')
                }

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // open asset as model
        async openAssetAsModel({ dispatch, rootGetters }, {
            asset,
            show = true
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to open.")
            }

            // ensure asset type is supported for input
            if (!rootGetters['api/isAssetTypeModel'](asset)) {
                throw new Error("Cannot open unsupported asset type.")
            }

            try
            {
                // call route to retrieve asset file (with default key 'default')
                const response = await dispatch('api/assetsAssetFileKeyGet', { 
                    asset 
                }, { root: true })

                // after the file has been retreived, set the asset as active model
                dispatch('setActiveModelAsset', asset)

                // has parent?
                if (asset.parent)
                {
                    // get parent asset (preview)
                    const parentAsset = rootGetters['api/getAsset'](asset.parent)

                    // parent retrieved successfully?
                    if (parentAsset) await dispatch('openAssetAsPreview', { asset: parentAsset, show })
                }

                // set window to model
                if (show) {
                    dispatch('setActiveWindowToModel') 
                }               

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // rate asset
        async rateAsset({ dispatch }, {
            assetId,
            rating
        })
        {
            // ensure assetId parameter is provided
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an assetId to rate.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // call route to rate asset
                const response = await dispatch('api/assetsAssetAdditionalPut', {
                    assetId,
                    additional: {
                        rating
                    }
                }, { root: true })

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // log event
                dispatch('app/logEvent', { 
                    name: "Sketchurizer Asset Rating", 
                    description: "User has rated the asset: " + assetId
                }, { root: true })

                return response
            }
            catch(error)
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // log bug
                dispatch('app/logBug', { 
                    name: "Sketchurizer Asset Rating",
                    description: "Rating update failed for asset: " + assetId
                }, { root: true })

                // provide user feedback
                dispatch('app/showToast', { 
                    text: 'Asset rating failed to update.', 
                    warning: true
                }, { root: true })

                throw error
            }
        },

        // edit asset
        async editAsset({ dispatch, getters, rootGetters }, {
            asset
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to edit.")
            }

            // ensure asset type is supported for input
            if (!rootGetters['api/isAssetTypeImage'](asset) && 
                !rootGetters['api/isAssetTypeLayer'](asset)) {
                throw new Error("Cannot open unsupported asset type.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })
                dispatch('app/setLoadingText', 'preparing sketch', { root: true })

                // reset vuex store 
                await dispatch('reset')
    
                // open asset as input
                const response = await dispatch('openAssetAsInput', { asset })

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // log event
                dispatch('app/logEvent', { 
                    name: "Sketchurizer Asset Edit", 
                    description: "User has started to edit the asset: " + asset.id
                }, { root: true })

                return response
            }
            catch(error)
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // provide user feedback
                dispatch('app/showToast', { 
                    text: 'Opening the asset for editing has failed.', 
                    warning: true
                }, { root: true })

                throw error
            }
        },

        // download asset
        async downloadAsset({ dispatch }, {
            asset,
            key = 'default',
            format = null
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to download.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // call route to download asset
                const response = await dispatch('api/assetsAssetDownloadHelper', {
                    asset,
                    key,
                    format
                }, { root: true })

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // provide user feedback
                dispatch('app/showToast', { 
                    text: 'Downloading the asset was successful.'
                }, { root: true })

                return response
            }
            catch(error)
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // provide user feedback
                 dispatch('app/showToast', { 
                    text: 'Downloading the asset has failed.', 
                    warning: true
                }, { root: true })

                throw error
            }
        },

        // delete asset 
        async deleteAsset({ commit, dispatch, getters }, {
            asset,
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to delete.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // call route to delete asset
                const response = await dispatch('api/assetsAssetDelete', {
                    assetId: asset.id
                }, { root: true })

                // get previews
                let previews = getters.getActivePreviewAssets

                // delete if preview asset and in previewAssets
                if (previews) 
                {
                    previews = previews.filter(preview => preview.id !== asset.id)
                    commit('SET_ACTIVE_PREVIEW_ASSETS', previews)
                }

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // provide user feedback
                dispatch('app/showToast', { 
                    text: 'Deleting the asset was successful.'
                }, { root: true })

                return response
            }
            catch(error)
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // provide user feedback
                 dispatch('app/showToast', { 
                    text: 'Deleting the asset has failed.', 
                    warning: true
                }, { root: true })

                throw error
            }
        },

        // delete asset flow
        async deleteAssetFlow({ dispatch, rootGetters }, {
            asset
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to delete.")
            }

            try
            {
                // pointer to current asset (for recursive delete)
                let currentAsset = asset

                // continue while currentAsset contains an asset
                while (currentAsset !== null && currentAsset !== undefined)
                {
                    // get parent asset
                    const parent = rootGetters['api/getAsset'](currentAsset.parent)

                    // get children (that depend on this asset)
                    const children = rootGetters['api/getChildAssets'](currentAsset.id)

                    // only delete if it does not have children
                    if (children == null || children.length <= 0)
                    {
                        await dispatch('deleteAsset', { asset: currentAsset })
                    }

                    // set asset for next recursion
                    currentAsset = parent
                }
            }
            catch(error)
            {
                throw error
            }
        },

        // create and set input asset (from raw input)
        async setInputAssetFromRaw({ commit, dispatch, getters, rootGetters }, {
            additional = null   
        })
        {
            try
            {
                // check if a new asset should be created
                if (getters.isActiveInputAssetOutdated || getters.getActiveInputAsset === null)
                {
                    // get raw input
                    const inputRaw = getters.getActiveInputRaw

                    // specify attributes
                    const type = rootGetters['api/keyAssetTypeImage']
        
                    // create thumbnail
                    const resolution = rootGetters['app/keyResolutionThumbnailDefault']
                    const thumbnail = await resizeImage(
                        inputRaw, 
                        resolution,
                        resolution
                    )

                    // call route to create asset
                    const response = await dispatch('api/assetsPost', {
                        type,
                        file: inputRaw,
                        thumbnail,
                        additional
                    }, { root: true })

                    // set input asset
                    commit('SET_ACTIVE_INPUT_ASSET', response)

                    return response
                }
                else
                {
                    return getters.getActiveInputAsset
                }
            }
            catch(error)
            {
                throw error
            }
        },

        async generateSketch({ dispatch }, {
            positive = '',
            negative = '',
            resolution = 768, 
            seeds = [ -1 ],
            additional = null
        })
        {
            try
            {
                // call route to generate sketch images
                const response = await dispatch('api/imagesFromPromptGeneratePost', {
                    positive,
                    negative,
                    resolution,
                    'postprocess': {
                        'active': true,
                        'style': 'sketch'
                    },
                    seeds,
                    additional
                }, { root: true })

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // generate preview from prompt
        async generatePreviewFromPrompt({ dispatch, getters }, {
            positive = '',
            negative = '',
            resolution = 1024, 
            seeds = [ -1 ],
            additional = null,
        })
        {
            // call route to generate layer from prompt
            return dispatch('api/layersFromPromptGeneratePost', {
                'positive': positive + getters.getAdditionalCustomPositive,
                'negative': negative + getters.getAdditionalCustomNegative,
                resolution,
                seeds,
                additional
            }, { root: true })
        },

        // generate preview from guidance
        async generatePreviewFromGuidance({ dispatch, getters }, {
            canny = 0,
            depth = 0,
            positive = '',
            negative = '',
            resolution = 1024,
            seeds = [ -1 ],
            additional = null
        })
        {
            try
            {
                // create or get input asset
                const response = await dispatch('setInputAssetFromRaw', { additional })

                // call route to generate layer from guidance
                return dispatch('api/layersFromGuidanceGeneratePost', {
                    parentId: response.id,
                    canny,
                    depth,
                    'positive': positive + getters.getAdditionalCustomPositive,
                    'negative': negative + getters.getAdditionalCustomNegative,
                    resolution,
                    seeds,
                    additional
                }, { root: true })
            }
            catch(error)
            {
                throw error
            }
        },
 
        // universal model generation (if parentId is null, we use input asset)
        async generateModel({ commit, dispatch, rootGetters }, {
            method = GENERATION_METHOD_DEFAULT,
            parent = null,
            additional = null
        })
        {
            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // check if a parent asset is not specified
                if (parent === null)
                {
                    // inform users about input upload
                    dispatch('app/setLoadingText', 'uploading input', { root: true })

                    // create or get input asset
                    const response = await dispatch('setInputAssetFromRaw', { additional })

                    // set input asset as parent
                    parent = response
                }

                // inform users about model generation
                dispatch('app/setLoadingText', 'generating model', { root: true })

                // determine correct route
                const route = rootGetters['api/isAssetTypeImage'](parent) ?
                    'api/modelsFromImageGeneratePost' : 'api/modelsFromLayerGeneratePost'

                // call route to generate model
                const asset = await dispatch(route, {
                    method, 
                    'parentId': parent.id,
                    additional: {
                        ...(additional || {}),
                        method
                    }
                }, { root: true })

                // wait for file to be generated
                await dispatch('api/assetsAssetFileKeyGet', {
                    asset,
                    timeMaximum: 300000 // wait five minutes for the model (at max)
                }, { root: true })
                
                // set model asset
                commit('SET_ACTIVE_MODEL_ASSET', asset)
                
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })
                
                return asset
            }
            catch(error)
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                throw error
            }
        },

        // remove background of input image
        async removeUploadBackground({ getters, dispatch, commit, rootGetters })
        {
            // get upload image
            const upload = getters.getActiveInputUpload
            if (upload === null || upload === undefined) {
                throw new Error("Please provide an image for background removal.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })
                dispatch('app/setLoadingText', 'uploading image', { root: true })

                // image asset
                let imageAsset = null

                // ensure image is in correct format
                if (!upload.startsWith('data:image/png;'))
                {
                    imageAsset = await dispatch('api/imagesFromFileConvertPost', {
                        file: upload,
                        mode: 'limit',
                        resolution: 1024
                    }, { root: true })
                }
                else
                {
                    imageAsset = await dispatch('api/assetsPost', {
                        type: rootGetters['api/keyAssetTypeImage'],
                        file: upload
                    }, { root: true })
                }

                // wait for image asset to be uploaded
                await dispatch('api/assetsAssetFileKeyGet', {
                    asset: imageAsset,
                }, { root: true })

                // inform the user
                dispatch('app/setLoadingText', 'removing background', { root: true })

                // convert image to layer (automatically removes background)
                const layerAsset = await dispatch('api/layersFromImageGeneratePost', {
                    parentId: imageAsset .id
                }, { root: true })

                // wait for layer asset to be generated
                await dispatch('api/assetsAssetFileKeyGet', {
                    asset: layerAsset,
                }, { root: true })

                // get new upload data
                const uploadNew = layerAsset.files['default'].data

                // set layer for upload
                commit("SET_ACTIVE_INPUT_UPLOAD", uploadNew)

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                return layerAsset
            }
            catch(error)
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                throw error
            }
        },

        // optimize model
        async optimizeModel({ dispatch, commit }, { 
            asset = null, 
            geometryReduce = true, 
            geometrySmooth = false
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to delete.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })
                dispatch('app/setLoadingText', 'optimizing model', { root: true })

                // call route to optimize model
                const response = await dispatch('api/modelsModelOptimizePost', {
                    asset,
                    centering_active: false,
                    reducing_active: geometryReduce,
                    reducing_tolerance: 2e-2,
                    inflating_active: false,
                    smoothing_active: geometrySmooth,
                    smoothing_filter: "laplacian",
                    smoothing_iterations: 1
                }, { root: true })

                // switch to optimized model
                commit('SET_VIEWER_FILE', VIEWER_FILE_OPTIMIZED)

                // wait for file to be generated
                await dispatch('api/assetsAssetFileKeyGet', {
                    asset,
                    key: 'optimize'
                }, { root: true })

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                return response
            }
            catch(error)
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                throw error
            }
        },

        setActiveInputRaw({ commit }, raw) {
            commit('SET_ACTIVE_INPUT_RAW', raw)
        },
        setActiveInputStrokes({ commit }, strokes) {
            commit('SET_ACTIVE_INPUT_STROKES', strokes)
        },
        setActiveInputBackground({ commit }, background) {
            commit('SET_ACTIVE_INPUT_BACKGROUND', background)
        },
        setActiveInputUpload({ commit }, upload) {
            commit('SET_ACTIVE_INPUT_UPLOAD', upload)
        },
        setActiveInputAsset({ commit }, asset) {
            commit('SET_ACTIVE_INPUT_ASSET', asset)
        },
        setActivePreviewAssets({ commit }, assets) {
            commit('SET_ACTIVE_PREVIEW_ASSETS', assets)
        },
        setActiveModelAsset({ commit }, asset) {
            commit('SET_ACTIVE_MODEL_ASSET', asset)
        },

        setActiveWindow({ commit }, window) {
            commit('SET_ACTIVE_WINDOW', window)
        },
        setActiveWindowToDefault({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_DEFAULT)
        },
        setActiveWindowToLibrary({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_LIBRARY)
        },
        setActiveWindowToInput({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_INPUT)
        },
        setActiveWindowToPreview({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_PREVIEW)
        },
        setActiveWindowToModel({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_MODEL)
        },

        setActiveWidget({ commit }, widget) {
            commit('SET_ACTIVE_WIDGET', widget)
        },
        setActiveWidgetToSketch({ commit }) {
            commit('SET_ACTIVE_WIDGET', WIDGET_SKETCH)
        },
        setActiveWidgetToUpload({ commit }) {
            commit('SET_ACTIVE_WIDGET', WIDGET_UPLOAD)
        },
        setActiveWidgetToCamera({ commit }) {
            commit('SET_ACTIVE_WIDGET', WIDGET_CAMERA)
        },

        setLibraryMode({ commit }, mode) {
            commit('SET_LIBRARY_MODE', mode)
        },
        setLibrarySearchQuery({ commit }, searchQuery) {
            commit('SET_LIBRARY_SEARCHQUERY', searchQuery)
        },
        setLibrarySortingType({ commit }, sortingType) {
            commit('SET_LIBRARY_SORTINGTYPE', sortingType)
        },
        setLibrarySortingOrder({ commit }, sortingOrder) {
            commit('SET_LIBRARY_SORTINGORDER', sortingOrder)
        },

        setInputStrength({ commit }, strength) {
            commit('SET_INPUT_STRENGTH', strength)
        },
        setPromptPositive({ commit }, prompt) {
            commit('SET_PROMPT_POSITIVE', prompt)
        },
        setPromptNegative({ commit }, prompt) {
            commit('SET_PROMPT_NEGATIVE', prompt)
        },

        setViewerMode({ commit }, mode) {
            commit('SET_VIEWER_MODE', mode)
        },
        setViewerModeToDefault({ commit }) {
            commit('SET_VIEWER_MODE', VIEWER_MODE_DEFAULT)
        },
        setViewerModeToTexture({ commit }) {
            commit('SET_VIEWER_MODE', VIEWER_MODE_TEXTURE)
        },
        setViewerModeToSolid({ commit }) {
            commit('SET_VIEWER_MODE', VIEWER_MODE_SOLID)
        },
        setViewerModeToWireframe({ commit }) {
            commit('SET_VIEWER_MODE', VIEWER_MODE_WIREFRAME)
        },
        setViewerFile({ commit }, file)
        {
            commit('SET_VIEWER_FILE', file)
        },
        setViewerFileToStandard({ commit })
        {
            commit('SET_VIEWER_FILE', VIEWER_FILE_STANDARD)
        },
        setViewerFileToOptimized({ commit })
        {
            commit('SET_VIEWER_FILE', VIEWER_FILE_OPTIMIZED)
        },

        setAdditionalTutorial({ dispatch, rootGetters }, tutorial) 
        {
            // get additional
            let additional = rootGetters['app/getAdditional']
            if (!additional) additional = {}

            // set tutorial
            additional.sketchTutorial = tutorial

            // change additional 
            dispatch('app/changeAdditional', additional, { root: true })
        },
        setAdditionalCustomPositive({ dispatch, rootGetters }, prompt) {
            // get additional
            let additional = rootGetters['app/getAdditional']
            if (!additional) additional = {}

            // set prompt custom positive
            additional.sketchCustomPositive = prompt

            // change additional 
            dispatch('app/changeAdditional', additional, { root: true })
        },
        setAdditionalCustomNegative({ dispatch, rootGetters }, prompt) {
            // get additional
            let additional = rootGetters['app/getAdditional']
            if (!additional) additional = {}

            // set prompt custom negative
            additional.sketchCustomNegative = prompt

            // change additional 
            dispatch('app/changeAdditional', additional, { root: true })
        },
        setAdditionalGenerationPersistent({ dispatch, rootGetters }, persistent) {
            // get additional
            let additional = rootGetters['app/getAdditional']
            if (!additional) additional = {}

            // set generation persistence
            additional.sketchGenerationPersistent = persistent

            // change additional 
            dispatch('app/changeAdditional', additional, { root: true })
        },
        setAdditionalGenerationMethod({ dispatch, rootGetters }, method) {
            // get additional
            let additional = rootGetters['app/getAdditional']
            if (!additional) additional = {}

            // set generation method
            additional.sketchGenerationMethod = method

            // change additional 
            dispatch('app/changeAdditional', additional, { root: true })
        },
        setAdditionalExportFormat({ dispatch, rootGetters }, format) {
            // get additional
            let additional = rootGetters['app/getAdditional']
            if (!additional) additional = {}

            // set export format
            additional.sketchExportFormat = format

            // change additional 
            dispatch('app/changeAdditional', additional, { root: true })
        }
    }
}